import React from 'react';
import PropTypes from 'prop-types';
import { Link, Outlet } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
// import Background from 'components/common/Background';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';

// import bgShape from 'assets/img/illustrations/bg-shape.png';
// import shape1 from 'assets/img/illustrations/shape-1.png';
// import halfCircle from 'assets/img/illustrations/half-circle.png';

const AuthCardLayout = ({
  leftSideContent,
  /* children, */ footer = false
}) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={8} className="py-3 position-relative">
          {/* <img
            className="bg-auth-circle-shape"
            src={bgShape}
            alt=""
            width="250"
          />
          <img
            className="bg-auth-circle-shape-2"
            src={shape1}
            alt=""
            width="150"
          /> */}
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col md={8} className="text-white text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    <div className="z-index-1 position-relative light">
                      <h3 style={{ marginBottom: '25px', textAlign: 'center' }}>
                        Bienvenidos a Instituto CertifiED Learning and
                        Development
                      </h3>

                      <h5 style={{ textAlign: 'left', marginBottom: '10px' }}>
                        ¿Por qué nosotros?
                      </h5>

                      <p className="text-white" style={{ textAlign: 'left' }}>
                        ¡Elige el Instituto CertifiED Learning and Development!
                      </p>
                      <div
                        style={{
                          marginBottom: '10px',
                          textAlign: 'justify'
                        }}
                      >
                        Certificamos tu nivel de inglés con una herramienta
                        basada en el Marco Común Europeo, con validez ante la
                        Secretaría de Educación. Además, certifícate como
                        maestro de inglés en nuestra certificación en
                        Metodologías de la Enseñanza del Inglés.
                      </div>
                      <div
                        style={{ marginBottom: '5px', textAlign: 'justify' }}
                      >
                        Nuestras certificaciones son vitalicias y en línea.
                        Contamos con una plataforma interactiva que facilita tu
                        aprendizaje y ofrecemos un programa de desarrollo de
                        habilidades blandas, ¡Incluyendo un viaje internacional
                        a una universidad Ivy League como premio! ¡Mejora tus
                        habilidades y alcanza el éxito con nosotros!
                      </div>

                      <h5 style={{ textAlign: 'left', marginTop: '35px' }}>
                        Nuestros servicios
                      </h5>

                      <div
                        style={{ marginBottom: '15px', textAlign: 'justify' }}
                      >
                        EPSt - English Proficiency Standard - Certificación del
                        idioma inglés basado en el Marco Común de Referencia
                        Europea con validez ante la Secretaría de Educación.
                      </div>
                      <div
                        style={{ marginBottom: '5px', textAlign: 'justify' }}
                      >
                        EMeT - English Methodologies Test - Certificación en la
                        Enseñanza del Idioma Inglés con validez ante la
                        Secretaría de Educación.
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    {leftSideContent}

                    {footer && (
                      <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
                        Contacto: 6622 99 99 99 Whatsapp:{' '}
                        <Link
                          className="text-decoration-underline text-white"
                          to="#!"
                        >
                          6622 99 99 99{' '}
                        </Link>
                        email:{' '}
                        <Link
                          className="text-decoration-underline text-white"
                          to="#!"
                        >
                          insitutocertified@certified.com{' '}
                        </Link>
                      </p>
                    )}
                  </div>
                </Col>
                <Col
                  md={4}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                  className=""
                >
                  {/* <div className="p-4 p-md-5 flex-grow-1">{children}</div> */}
                  <div className="p-4 p-md-4 flex-grow-1">
                    <Outlet />
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.bool
};

export default AuthCardLayout;
