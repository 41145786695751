import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../../assets/scss/wizard/epstAudioPlayer.module.scss';

const EpstAudioPlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);

  return (
    <div>
      <audio
        className={`${styles.audioPlayer}`}
        ref={audioRef}
        src={`${process.env.REACT_APP_API_REMOTE_SERVER}${audioSrc}`}
        controls
        controlsList="nodownload noremoteplayback noplaybackrate"
      >
        <source
          src={`${process.env.REACT_APP_API_REMOTE_SERVER}${audioSrc}`}
          type="audio/mpeg"
        />
        Your browser does not support the audio element.
      </audio>
      {/* <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button> */}
    </div>
  );
};

EpstAudioPlayer.propTypes = {
  audioSrc: PropTypes.string.isRequired
};

export default EpstAudioPlayer;
