import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

const useBackendRequest = () => {
  const routeNavigate = useNavigate();

  const error500State = {
    title: 'Whoops, algo salió mal!',
    errorCode: 500,
    message:
      'Intenta refrescar la pagina o regresa a intentarlo de nuevo. si el problema persiste,',
    footerMessage: `contáctanos`
  };

  const sendRequest = useCallback(
    async (
      requestConfig,
      setIsLoading,
      applyReqResultData,
      resolveBackendError = undefined
    ) => {
      try {
        setIsLoading(true);
        const sendedRequest = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : 'GET',
          headers: requestConfig.headers ? requestConfig.headers : {},
          body: requestConfig.body ? requestConfig.body : null
        });
        if (sendedRequest.ok && sendedRequest.status === 200) {
          const jsonResult = await sendedRequest.json();
          applyReqResultData(jsonResult);
        } else if (!sendedRequest.ok && sendedRequest.status === 401) {
          const jsonResult = await sendedRequest.json();
          toast.error(`${jsonResult.message}`, {
            position: 'top-center',
            pauseOnHover: true
          });
          routeNavigate('/');
        } else if (!sendedRequest.ok && sendedRequest.status === 409) {
          const updateUserResponse = await sendedRequest.json();
          toast.error(`${updateUserResponse.message}`, {
            position: 'top-center',
            pauseOnHover: true
          });
          routeNavigate('/dashboard/lms');
        } else if (!sendedRequest.ok && sendedRequest.status === 422) {
          const jsonResult = await sendedRequest.json();
          resolveBackendError(jsonResult.validationErrors);
        } else if (!sendedRequest.ok && sendedRequest.status === 500) {
          routeNavigate('/errors/500', {
            state: error500State
          });
        }
        setIsLoading(false);
      } catch (error) {
        routeNavigate('/errors/500', {
          state: error500State
        });
      }
    },
    []
  );

  return { sendRequest };
};

export default useBackendRequest;
