import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const LastCert = ({ lastCheckedCert }) => {
  const [styleClass] = useState('pt-4 p-xxl-0 pb-0 pe-md-0');
  const [color] = useState('primary');

  return (
    <Col
      xxl={3}
      md={6}
      className={`${styleClass} border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0 px-3 text-center`}
    >
      <div className={`icon-circle icon-circle-${color}`}>
        <FontAwesomeIcon
          icon={faCalendarCheck}
          className={`fs-2 text-${color}`}
        />
      </div>
      <h5 className="mb-1 font-sans-serif">
        <span className="fw-normal text-700">
          {' ' + lastCheckedCert.title}
        </span>
      </h5>
      <h6 className="mb-2 font-sans-serif">
        <span className="fw-normal text-500">{lastCheckedCert.emailFrom}</span>
      </h6>
      <h6 className="mb-2 font-sans-serif">
        <span className="fw-normal text-500">{lastCheckedCert.checkDate}</span>
      </h6>
    </Col>
  );
};

LastCert.propTypes = {
  lastCheckedCert: PropTypes.object.isRequired
};

export default LastCert;
