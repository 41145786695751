import React, { useState } from 'react';
import { Dropdown, Button, Form, Spinner } from 'react-bootstrap';
import { getSize } from 'helpers/utils';
import Flex from 'components/common/Flex';
import { useDropzone } from 'react-dropzone';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import CardDropdown from 'components/common/CardDropdown';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { toast } from 'react-toastify';
import useBackendRequest from '../../../hooks/useBackendRequest';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';
import styles from '../../../assets/scss/pages/certifications/fileEmetUploader.module.scss';

// import { Form } from 'react-router-dom';
// import { useSubmit } from 'react-router-dom';
// import { useFetcher /*, useLoaderData */ } from 'react-router-dom';

const FileEmetUploader = ({
  setValidEmetQuestions,
  setInValidEmetQuestions
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const schema = yup
    .object({
      emetFile: yup
        .array()
        .required(
          'Es necesario seleccionar el archivo de certtificación EMet correspondiente.'
        )
    })
    .required();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors,
    setError
  } = useForm({
    resolver: yupResolver(schema)
  });

  const [files, setFiles] = useState([]);
  // const [file, setFile] = useState(null);

  // const [file, setFile] = useState(null);
  // const fetcher = useFetcher();
  // const submit = useSubmit();
  const { sendRequest } = useBackendRequest();

  const handleFileRead = async selectedFile => {
    try {
      const fileContent = await readFileContent(selectedFile);
      return fileContent;
    } catch (error) {
      console.error('Error reading file:', error);
    }
  };

  const readFileContent = selectedFile => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = event => {
        const content = event.target.result;
        resolve(content);
      };

      fileReader.onerror = error => {
        reject(error);
      };

      fileReader.readAsArrayBuffer(selectedFile);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls', '.xlsx']
    },
    onDrop: async acceptedFiles => {
      if (errors.emetFile) {
        clearErrors('emetFile');
      }

      setValue('emetFile', acceptedFiles);
      setFiles([
        // ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);

      const selectedFile = acceptedFiles[0];
      if (selectedFile) {
        let errorMessage = '';

        if (selectedFile.name !== 'emet.xlsx') {
          errorMessage =
            'No es un archivo válido, selecciona el archivo emet.xlsx correspondiente.';
          // errorMessage = '';
        } else {
          const fileContent = await handleFileRead(selectedFile);
          const workbook = XLSX.read(fileContent, { type: 'array' });

          const filterdMetaInfo = workbook.SheetNames.find(
            sheet => sheet === 'metaInfo'
          );

          if (filterdMetaInfo === undefined) {
            errorMessage =
              'No es un archivo válido, este archivo no contiene la meta información correspondiente';
            // errorMessage = '';
          } else {
            /* Get version file */
            const worksheet = workbook.Sheets[filterdMetaInfo];
            const specificCell = XLSX.utils.encode_cell({ r: 0, c: 10 });
            const userSheetVersionFile = worksheet[specificCell]?.v;
            if (
              userSheetVersionFile !== process.env.REACT_APP_USERS_SHEET_VERSION
            ) {
              errorMessage =
                'Favor de seleccionar el archivo emet.xlsx con la versión correspondiente';
              // errorMessage = '';
            } else {
              const filteredSheets = workbook.SheetNames.find(
                sheet => sheet === 'EMeT'
              );

              if (filteredSheets === undefined) {
                errorMessage =
                  'No es un archivo válido, este archivo no contiene una hoja con el listado de preguntas para EMeT';
                // errorMessage = '';
              }
            }
          }
        }
        if (errorMessage !== '') {
          setError('emetFile', {
            message: errorMessage
          });
          setBtnDisable(true);
        } else {
          setBtnDisable(false);
        }
      }
    },
    multiple: false
  });

  const handleRemove = path => {
    const filteredFiles = files.filter(file => file.path !== path);
    setFiles(filteredFiles);
    setValue('emetFile', filteredFiles.length ? filteredFiles : null);
    if (errors.emetFile) {
      clearErrors('emetFile');
    }
    setBtnDisable(true);
  };

  const applyReqResultDataOnLoadFile = jsonResult => {
    toast.success(`${jsonResult.message}`, {
      position: 'top-center',
      pauseOnHover: true
    });

    setValidEmetQuestions(jsonResult.validEmetQuestions);
    setInValidEmetQuestions(jsonResult.invalidEmetQuestions);
    setFiles([]);
    setValue('emetFile', null);
  };

  const resolveBackendError = backErrors => {
    backErrors.forEach(bkError => {
      setError(bkError.path, { message: bkError.msg });
    });
  };

  async function onSubmit(frmData) {
    try {
      const emetFileSelected = frmData.emetFile[0];
      const formInfo = new FormData();
      formInfo.append('emetFile', emetFileSelected);
      sendRequest(
        {
          url: `${process.env.REACT_APP_API_REMOTE_SERVER}api/artifact/uploadEmetCatalog`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          },
          body: formInfo
        },
        setIsLoading,
        applyReqResultDataOnLoadFile,
        resolveBackendError
      );
    } catch (error) {
      throw new Response(null, { status: 500 });
    }
  }

  const isButtonDisabled = btnDisable || isLoading;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div
        {...getRootProps({
          className: `${classNames('dropzone-area py-6', {
            'is-invalid': errors.emetFile
          })}`
        })}
      >
        <input name="emetFile" {...getInputProps()} />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">
            Seleccionar o arrastra el archivo emet.xlsx
          </p>
        </Flex>
      </div>
      <div className="invalid-feedback">{errors.emetFile?.message}</div>
      <div>
        {files.map(file => (
          <Flex
            alignItems="center"
            className="py-3 border-bottom btn-reveal-trigger"
            key={file.path}
          >
            {/* <img
              className="rounded"
              width={40}
              height={40}
              src={file.preview}
              alt={file.path}
            /> */}

            <Flex
              justifyContent="between"
              alignItems="center"
              className="ms-3 flex-1"
            >
              <div>
                <h6>{file.path}</h6>
                <Flex className="position-relative" alignItems="center">
                  <p className="mb-0 fs--1 text-400 line-height-1">
                    <strong>{getSize(file.size)}</strong>
                  </p>
                </Flex>
              </div>
            </Flex>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleRemove(file.path)}
                >
                  Remover
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        ))}
      </div>
      <Button
        disabled={isButtonDisabled}
        variant="primary"
        type="submit"
        className="mt-3"
        size="md"
      >
        {isLoading && (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {!isLoading && <span>Cargar</span>}
        {isLoading && (
          <span className={`${styles.loadButton}`}>Cargando...</span>
        )}
      </Button>
    </Form>
  );
};

FileEmetUploader.propTypes = {
  setValidEmetQuestions: PropTypes.func,
  setInValidEmetQuestions: PropTypes.func
};
export default FileEmetUploader;
