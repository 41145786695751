import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import emetCertImg from 'assets/img/support-desk/reports/5.png';
import epstCertImg from 'assets/img/support-desk/reports/6.png';
// import { useFetcher } from 'react-router-dom';

const CertificationEpstItem = ({ cert }) => {
  return (
    <Col lg={6} xxl={6}>
      <Flex
        alignItems="center"
        className="px-4 py-x1 bg-light rounded-3 border position-relative"
      >
        <img src={epstCertImg} alt="" width="39" />
        <div className="ms-3 my-x1">
          <h5 className="fs-0 fw-semi-bold mb-2">
            <Link
              to="/certifications/results/epst"
              state={{ ...cert }}
              className="text-900 hover-primary stretched-link"
            >
              {cert.description}
            </Link>
          </h5>
        </div>
      </Flex>
    </Col>
  );
};

CertificationEpstItem.propTypes = {
  cert: PropTypes.shape({
    certificationId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    certification: PropTypes.string.isRequired,
    kindCertification: PropTypes.string.isRequired,
    userType: PropTypes.string,
    evaluatedBy: PropTypes.string,
    finishedCertificationDate: PropTypes.string.isRequired,
    finishedEvaluatedDate: PropTypes.string,
    college: PropTypes.string.isRequired,
    openCertificateDate: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    test: PropTypes.string,
    grammarScore: PropTypes.number.isRequired,
    grammarPercentage: PropTypes.string.isRequired,
    listeningScore: PropTypes.number.isRequired,
    listeningPercentage: PropTypes.string.isRequired,
    readingScore: PropTypes.number.isRequired,
    readingPercentage: PropTypes.string.isRequired,
    writingScore: PropTypes.number.isRequired,
    writingPercentage: PropTypes.string.isRequired,
    speakingScore: PropTypes.number.isRequired,
    speakingPercentage: PropTypes.string.isRequired,
    totalScore: PropTypes.number.isRequired,
    totalPercentage: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired
  })
};

const CertificationEmetItem = ({ cert }) => {
  return (
    <Col lg={6} xxl={6}>
      <Flex
        alignItems="center"
        className="px-4 py-x1 bg-light rounded-3 border position-relative"
      >
        <img src={emetCertImg} alt="" width="39" />
        <div className="ms-3 my-x1">
          <h5 className="fs-0 fw-semi-bold mb-2">
            <Link
              to="/certifications/results/emet"
              state={{ ...cert }}
              className="text-900 hover-primary stretched-link"
            >
              {cert.description}
            </Link>
          </h5>
        </div>
      </Flex>
    </Col>
  );
};

CertificationEmetItem.propTypes = {
  cert: PropTypes.shape({
    certificationId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    birthday: PropTypes.string.isRequired,
    certification: PropTypes.string.isRequired,
    kindCertification: PropTypes.string.isRequired,
    openCertificateDate: PropTypes.string.isRequired,
    finishedCertificationDate: PropTypes.string.isRequired,
    college: PropTypes.string.isRequired,
    test: PropTypes.string,
    userType: PropTypes.string,
    evaluatedBy: PropTypes.string,
    section1Title: PropTypes.string.isRequired,
    section1Points: PropTypes.number.isRequired,
    section1Percentage: PropTypes.string.isRequired,
    section2Title: PropTypes.string.isRequired,
    section2Points: PropTypes.number.isRequired,
    section2Percentage: PropTypes.string.isRequired,
    section3Title: PropTypes.string.isRequired,
    section3Points: PropTypes.number.isRequired,
    section3Percentage: PropTypes.string.isRequired,
    section4Title: PropTypes.string.isRequired,
    section4Points: PropTypes.number.isRequired,
    section4Percentage: PropTypes.string.isRequired,
    totalScore: PropTypes.number.isRequired,
    totalPercentage: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired
  })
};

const EvaluatedCertification = () => {
  const backendUserCerts = useLoaderData();
  const userCerts = backendUserCerts.userCerts;

  const routeNavigate = useNavigate();

  const [epstCertif, setEpstCertif] = useState([]);
  const [emetCertif, setEmetCertif] = useState([]);

  useEffect(() => {
    if (userCerts.epst.length === 0 && userCerts.emet.length === 0) {
      routeNavigate('/dashboard/lms');
    } else if (userCerts.epst.length > 0 && userCerts.emet.length === 0) {
      setEpstCertif(userCerts.epst);
      routeNavigate('/certifications/results/epst', {
        state: { ...userCerts.epst[0] }
      });
    } else if (userCerts.epst.length === 0 && userCerts.emet.length > 0) {
      routeNavigate('/certifications/results/emet', {
        state: { ...userCerts.emet[0] }
      });
    } else if (userCerts.epst.length > 0 && userCerts.emet.length > 0) {
      setEpstCertif(userCerts.epst);
      setEmetCertif(userCerts.emet);
    }
  }, []);

  return (
    <>
      {
        /* userCerts.length > 0 && */
        userCerts.epst.length > 0 && userCerts.emet.length > 0 && (
          <Card>
            <Card.Header className="bg-light">
              <h5 className="mb-0">Tus certificaciones</h5>
            </Card.Header>
            <Card.Body>
              <Row className="g-3">
                {epstCertif.map((certification, index) => (
                  <CertificationEpstItem key={index} cert={certification} />
                ))}
                {emetCertif.map((certification, index) => (
                  <CertificationEmetItem key={index} cert={certification} />
                ))}
              </Row>
            </Card.Body>
          </Card>
        )
      }
    </>
  );
};

export default EvaluatedCertification;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getUserCertifications`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};

//   export const action = async ({ request }) => {
//     let resultAction = null;

//     try {
//       if (request.method === 'POST') {
//         const data = await request.formData();
//         const trigger = data.get('trigger');

//         if (trigger === 'GET-EPST-EVALUATED') {
//           const coleges = data.get('coleges');
//           const email = data.get('email');

//           const certificationCreatedFrom = data.get('certificationCreatedFrom');
//           const certificationCreatedTo = data.get('certificationCreatedTo');

//           const certificationFilter = JSON.stringify({
//             coleges: coleges,
//             email: email,
//             certificationCreatedFrom: certificationCreatedFrom,
//             certificationCreatedTo: certificationCreatedTo
//           });

//           resultAction = await fetch(
//             `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getEpstEvaluatedCertifications`,
//             {
//               method: 'POST',
//               headers: {
//                 'content-type': 'application/json; charset=UTF-8',
//                 Authorization: `Bearer ${localStorage.getItem('token')}`
//               },
//               body: JSON.stringify({ certificationsFilter: certificationFilter })
//             }
//           );
//         }
//       }
//     } catch (error) {
//       throw new Response(null, { status: 500 });
//     }

//     if (!resultAction.ok && resultAction.status !== 422) {
//       throw resultAction;
//     }

//     return resultAction;
//   };
