import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Error500Router from './Error500Router';
import PropTypes from 'prop-types';

const RootError500 = ({ error }) => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Logo width={120} />
          <Error500Router error={error} />
        </Col>
      </Row>
    </Section>
  );
};

RootError500.propTypes = {
  error: PropTypes.object.isRequired
};

export default RootError500;
