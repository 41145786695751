import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Form,
  Nav,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  Spinner
} from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import EpstReadingForm from './EpstReadingForm';
import EpstListeningForm from './EpstListeningForm';
import IconButton from 'components/common/IconButton';
import WizardModalEpst from './WizardModalEpst';
import Flex from 'components/common/Flex';
import Break from './EpstBreak';
import { useDispatch, useSelector } from 'react-redux';
import { useFetcher } from 'react-router-dom';
import { epstCertActions } from 'store/epstCert';
import styles from '../../../src/assets/scss/wizard/wizardLayoutEpst.module.scss';
import EpstSuccess from './EpstSuccess';
import EpstBreak from './EpstBreak';
import EpstGrammarFrom from './EpstGrammarForm';
import EpstWitingFrom from './EpstWritingForm';
import EpstSpeakingFrom from './EpstSpeakingForm';

const WizardLayoutEpst = ({ variant, validation, progressBar }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const fetcher = useFetcher();
  const step = useSelector(state => state.epstCert.step);
  const currentQuestion = useSelector(state => state.epstCert.currentQuestion);
  const currentSection = useSelector(state => state.epstCert.currentSection);
  const speakingAnswered = useSelector(
    state => state.epstCert.speakingAnswered
  );

  const email = useSelector(state => state.user.userInfo.email);
  const { data, state } = fetcher;

  const remainingTime = useSelector(state => state.epstCert.remainingTime);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    clearErrors,
    setError,
    formState
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'book-open',
      label: 'Grammar',
      sectionDesc: 'Grammar'
    },

    {
      icon: 'clock',
      label: 'Break',
      sectionDesc: 'Break'
    },
    {
      icon: 'book-open',
      label: 'Reading',
      sectionDesc: 'Reading'
    },
    {
      icon: 'clock',
      label: 'Break',
      sectionDesc: 'Break'
    },
    {
      icon: 'book-open',
      label: 'Listening',
      sectionDesc: 'Listening'
    },
    {
      icon: 'clock',
      label: 'Break',
      sectionDesc: 'Break'
    },
    {
      icon: 'book-open',
      label: 'Writing',
      sectionDesc: 'Writing'
    },
    {
      icon: 'clock',
      label: 'Break',
      sectionDesc: 'Break'
    },
    {
      icon: 'book-open',
      label: 'Speaking',
      sectionDesc: 'Speaking'
    },
    {
      icon: 'thumbs-up',
      label: 'Done',
      sectionDesc: 'DONE'
    }
  ];

  const getNextQuestion = () => {
    setIsLoading(true);
    fetcher.submit(
      {
        trigger: 'GET-NEXT-QUESTION'
      },
      { method: 'POST' }
    );
  };

  const sendAnswer = answer => {
    const numberOfQuestion = currentQuestion.numberOfQuestion;

    if (numberOfQuestion === 88888 && remainingTime <= 0) {
      getNextQuestion();
    } else if (
      numberOfQuestion !== 99999 &&
      numberOfQuestion !== 88888 &&
      numberOfQuestion !== 0
    ) {
      setIsLoading(true);
      if (currentSection === 'Speaking') {
        fetcher.submit(
          {
            trigger: 'POST-ANSWER-SPEAKING-QUESTION',
            numberOfQuestion: numberOfQuestion,
            kindCertification: currentQuestion.kindCertification,
            email: email
          },
          { method: 'POST' }
        );
      } else {
        fetcher.submit(
          {
            trigger: 'POST-ANSWER-QUESTION',
            selectedAnswer: answer,
            numberOfQuestion: numberOfQuestion
          },
          { method: 'POST' }
        );
      }
    }
  };

  const onSubmitData = data => {
    if (currentSection !== 'Speaking') {
      sendAnswer(data.userAnswer);
    } else {
      getNextQuestion();
    }
  };
  const onError = () => {
    if (!validation) {
      clearErrors();
      // dispatch(emetCertActions.setStep(step + 1));
    }
  };

  // const toggle = () => setModal(!modal);

  const handleNavs = /*targetStep*/ () => {};

  useEffect(() => {
    if (remainingTime <= 0) {
      getNextQuestion();
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (remainingTime > 0) {
        dispatch(epstCertActions.decrementRemainingTime());
      } else if (remainingTime <= 0) {
        sendAnswer(null);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [remainingTime]);

  useEffect(() => {
    if (state === 'idle' && data && data.nextQuestion) {
      const nextQuest = data.nextQuestion[0];
      dispatch(epstCertActions.setCurrentQuestion(nextQuest));
      dispatch(epstCertActions.setRemainingTime(nextQuest.time));

      setIsLoading(false);
      if (currentSection === 'Speaking') {
        dispatch(epstCertActions.setSpeakingAnswered(false));
      }
    } else if (state === 'idle' && data && data.wasQuestionAnswerUpdate) {
      /* call dispatch function*/
      const wasQuestionAnswerUpdate = data.wasQuestionAnswerUpdate;
      if (wasQuestionAnswerUpdate) {
        /* call next question*/
        getNextQuestion();
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  }, [state, data]);

  return (
    <>
      <WizardModalEpst modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className={`theme-wizard mb-5 `}
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills'
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {navItems.map((item, index) => (
              <NavItemPill
                key={`${item.label}-${index}`}
                index={index + 1}
                step={step}
                handleNavs={handleNavs}
                icon={item.icon}
                label={item.label}
                sectionDesc={item.sectionDesc}
              />
            ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 10} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {isLoading && (
            <div className={`${styles.spinnerLoading}`}>
              <Spinner animation="border" role="status"></Spinner>
            </div>
          )}
          {step === 1 && (
            <EpstGrammarFrom
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
              sendAnswer={sendAnswer}
              getNextQuestion={getNextQuestion}
            />
          )}
          {step === 2 && <EpstBreak />}
          {step === 3 && (
            <EpstReadingForm
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )}
          {step === 4 && <Break />}
          {step === 5 && (
            <EpstListeningForm
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          )}
          {step === 6 && <Break />}
          {step === 7 && (
            <EpstWitingFrom
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          {step === 8 && <Break />}
          {step === 9 && (
            <EpstSpeakingFrom
              setError={setError}
              clearErrors={clearErrors}
              formState={formState}
            />
          )}
          {currentSection === 'DONE' && (
            <EpstSuccess setIsLoading={setIsLoading} reset={reset} />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 10,
            ' d-flex': step < 10
          })}
        >
          {/* <IconButton
            variant="link"
            icon={'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              dispatch(emetCertActions.setStep(step - 1));
            }}
          >
            Prev
          </IconButton> */}

          {!isLoading &&
            currentSection !== 'DONE' &&
            currentSection !== 'BREAK' &&
            ((currentSection !== 'Speaking' && !speakingAnswered) ||
              (currentSection === 'Speaking' && speakingAnswered)) && (
              <IconButton
                variant="primary"
                className="ms-auto px-5"
                type="submit"
                icon={'chevron-right'}
                iconAlign="right"
                transform="down-1 shrink-4"
              >
                {currentSection === 'Speaking' ? 'Next' : 'Submit'}
              </IconButton>
            )}
        </Card.Footer>
      </Card>
    </>
  );
};

WizardLayoutEpst.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

const NavItemPill = ({ index, step, handleNavs, icon, label, sectionDesc }) => {
  const sectionTooltip = (
    <Tooltip id="tooltip" className={styles.customTooltip}>
      {sectionDesc}
    </Tooltip>
  );
  return (
    <Nav.Item>
      {label.trim() !== 'BREAK' && (
        <OverlayTrigger placement="bottom" overlay={sectionTooltip}>
          <Nav.Link
            className={classNames('fw-semi-bold', {
              done: step > index,
              active: step === index
            })}
            onClick={() => handleNavs(index)}
          >
            <Flex alignItems="center" justifyContent="center">
              <FontAwesomeIcon icon={icon} />
              <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
            </Flex>
          </Nav.Link>
        </OverlayTrigger>
      )}
      {label.trim() === 'BREAK' && (
        <Nav.Link
          className={classNames('fw-semi-bold', {
            done: step > index,
            active: step === index
          })}
          onClick={() => handleNavs(index)}
        >
          <Flex alignItems="center" justifyContent="center">
            <FontAwesomeIcon icon={icon} />
            <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
          </Flex>
        </Nav.Link>
      )}
    </Nav.Item>
  );
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sectionDesc: PropTypes.string.isRequired
};

export default WizardLayoutEpst;
