import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
// import ErrorLayout from '../layouts/ErrorLayout';

import Lms from 'components/dashboards/lms';
import { loader as lmsLoader } from '../components/dashboards/lms';
import { action as lmsAction } from '../components/dashboards/lms';

// import { action as epstUploadAction } from '../components/pages/certifications/UploadEpstCert';
import CardLogin from 'components/authentication/card/Login';
import CardLogout from 'components/authentication/card/Logout';
import CardLockScreen from 'components/authentication/card/LockScreen';

import ErrorRouterHandler from 'components/errors/ErrorRouterHandler';
import UploadUsers from 'components/pages/user/UploadUsers';
import SearchUsers from 'components/pages/user/SearchUsers';
import EpstSearch from 'components/pages/certifications/EpstSearch';
import EpstEvaluatedDownload from 'components/pages/certifications/EpstEvaluatedDownload';
import EmetEvaluatedDownload from 'components/pages/certifications/EmetEvaluatedDownload';
import StaticsReport from 'components/pages/certifications/StaticsReport';
import ExamineCert from 'components/pages/certifications/ExamineCert';
import UploadEmetCert from 'components/pages/certifications/UploadEmetCert';
import AuthCardLayout from '../layouts/AuthCardLayout';
import UploadEpstCert from 'components/pages/certifications/UploadEpstCert';
import EvaluatedCertification from '../components/pages/certifications/results/EvaluatedCertification';

import { loader as searchUserLoader } from '../components/pages/user/SearchUsers';
import { action as examineCertAction } from '../components/pages/certifications/ExamineCert';

import { action as searchUserAction } from '../components/pages/user/SearchUsers';

import { loader as downloadCertLoader } from '../components/pages/certifications/EpstSearch';
import { action as downloadCertAction } from '../components/pages/certifications/EpstSearch';

import { loader as uploadEpstCertLoader } from '../components/pages/certifications/UploadEpstCert';
import { loader as uploadEmetCertLoader } from '../components/pages/certifications/UploadEmetCert';
import { loader as uploadUserLoader } from '../components/pages/user/UploadUsers';
import { loader as emetCertQuestionsLoader } from '../components/pages/certifications/emet/EmetCertQuestions';
import { loader as epstCertQuestionsLoader } from '../components/pages/certifications/epst/EpstCertQuestions';
import { loader as epstResultsLoader } from '../components/pages/certifications/results/Epst';
import { loader as emetResultsLoader } from '../components/pages/certifications/results/Emet';
import { loader as examineCerLoader } from '../components/pages/certifications/ExamineCert';
import { loader as initCerLoader } from '../components/pages/certifications/start-cert/InitCert';

import { loader as emetEvaluatedDownloadLoader } from '../components/pages/certifications/EmetEvaluatedDownload';
import { action as emetEvaluatedDownloadAction } from '../components/pages/certifications/EmetEvaluatedDownload';

import { loader as epstEvaluatedDownloadLoader } from '../components/pages/certifications/EpstEvaluatedDownload';
import { action as epstEvaluatedDownloadAction } from '../components/pages/certifications/EpstEvaluatedDownload';
import { loader as qrCodeResultLoader } from '../components/pages/certifications/results/QRCodeResults';

import { loader as staticsReportLoader } from '../components/pages/certifications/StaticsReport';
import { action as staticsReportAction } from '../components/pages/certifications/StaticsReport';

import { loader as evaluatedResultsLoader } from '../components/pages/certifications/results/EvaluatedCertification';

import { action as initCertAction } from '../components/pages/certifications/start-cert/InitCert';
import { action as emetCertQuestionsAction } from '../components/pages/certifications/emet/EmetCertQuestions';
import { action as epstCertQuestionsAction } from '../components/pages/certifications/epst/EpstCertQuestions';

import InitCert from 'components/pages/certifications/start-cert/InitCert';
import EmetCertQuestions from 'components/pages/certifications/emet/EmetCertQuestions';
import EpstCertQuestions from 'components/pages/certifications/epst/EpstCertQuestions';
import CertificationLayout from '../layouts/CertificationLayout';
import RootError500 from 'components/errors/RootError500';
import RootError404 from 'components/errors/RootError404';
import Epst from '../components/pages/certifications/results/Epst';
import Emet from '../components/pages/certifications/results/Emet';
import QRCodeResults from '../components/pages/certifications/results/QRCodeResults';

const error500State = {
  title: 'Whoops, algo salió mal!',
  errorCode: 500,
  message:
    'Intenta refrescar la pagina o regresa a intentarlo de nuevo. si el problema persiste,',
  footerMessage: `contáctanos`
};

const error404State = {
  title: 'La página que estas buscando no existe.',
  errorCode: 404,
  message:
    'Asegúrate de que la ruta que proporcionaste sea correcta o que la página no se haya movido.',
  footerMessage: `ir a login`
};

const SystemRoutes = () => {
  const routes = createBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorRouterHandler />,
      children: [
        {
          path: 'errors/',
          element: <MainLayout />,
          children: [
            {
              path: '500',
              element: <RootError500 error={error500State} />
            },
            {
              path: '404',
              element: <RootError404 error={error404State} />
            }
          ]
        },

        {
          path: '',
          element: <AuthCardLayout />,
          children: [
            { index: true, element: <CardLogin /> },
            { path: 'card/logout', element: <CardLogout /> },
            {
              path: 'card/lock-screen',
              element: <CardLockScreen />
            }
          ]
        },
        {
          path: '',
          element: <MainLayout />,
          children: [
            {
              path: 'dashboard/',
              children: [
                {
                  path: 'lms',
                  element: <Lms />,
                  loader: lmsLoader,
                  action: lmsAction,
                  shouldRevalidate: () => false
                }
              ]
            },
            {
              path: 'certifications/',
              children: [
                {
                  path: '',
                  /* element: <CertificationLayout />, */
                  children: [
                    {
                      path: 'epst-upload',
                      element: <UploadEpstCert />,
                      loader: uploadEpstCertLoader
                    },
                    {
                      path: 'emet-upload',
                      element: <UploadEmetCert />,
                      loader: uploadEmetCertLoader
                    },
                    {
                      path: 'epst-search',
                      element: <EpstSearch />,
                      loader: downloadCertLoader,
                      action: downloadCertAction,
                      shouldRevalidate: () => false
                    },
                    {
                      path: 'emet-download',
                      element: <EmetEvaluatedDownload />,
                      loader: emetEvaluatedDownloadLoader,
                      action: emetEvaluatedDownloadAction,
                      shouldRevalidate: () => false
                    },
                    {
                      path: 'epst-download',
                      element: <EpstEvaluatedDownload />,
                      loader: epstEvaluatedDownloadLoader,
                      action: epstEvaluatedDownloadAction,
                      shouldRevalidate: () => false
                    },
                    {
                      path: 'examine',
                      element: <ExamineCert />,
                      loader: examineCerLoader,
                      action: examineCertAction
                    },
                    {
                      path: 'directions',
                      element: <InitCert />,
                      loader: initCerLoader,
                      action: initCertAction,
                      shouldRevalidate: () => false
                    },
                    {
                      path: 'statics-report',
                      element: <StaticsReport />,
                      loader: staticsReportLoader,
                      action: staticsReportAction,
                      shouldRevalidate: () => false
                    },
                    {
                      path: 'results/qrcode',
                      loader: qrCodeResultLoader,
                      element: <QRCodeResults />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'certifications/',
          element: <CertificationLayout />,
          children: [
            {
              path: '',
              children: [
                {
                  path: 'emetcertquestions',
                  element: <EmetCertQuestions />,
                  loader: emetCertQuestionsLoader,
                  action: emetCertQuestionsAction
                },
                {
                  path: 'epstcertquestions',
                  element: <EpstCertQuestions />,
                  loader: epstCertQuestionsLoader,
                  action: epstCertQuestionsAction
                },
                {
                  path: 'results',
                  element: <EvaluatedCertification />,
                  loader: evaluatedResultsLoader
                  /*action: epstCertQuestionsAction */
                },
                {
                  path: 'results/epst',
                  element: <Epst />,
                  loader: epstResultsLoader
                },
                {
                  path: 'results/emet',
                  element: <Emet />,
                  loader: emetResultsLoader
                }
              ]
            }
          ]
        },
        {
          path: 'users/',
          element: <MainLayout />,
          children: [
            {
              path: 'upload',
              element: <UploadUsers />,
              loader: uploadUserLoader
            },
            {
              path: 'search',
              element: <SearchUsers />,
              loader: searchUserLoader,
              action: searchUserAction,
              shouldRevalidate: () => false
            }
          ]
        }
      ]
    }
  ]);

  return <RouterProvider router={routes} />;
};

export default SystemRoutes;
