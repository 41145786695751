import { createSlice } from '@reduxjs/toolkit';

const emetCertInitialState = {
  currentQuestion: {
    numberOfQuestion: 0,
    instruction: '',
    question: '',
    section: '',
    time: 0,
    answer: []
  },
  remainingTime: 0,
  userAnswer: {},
  step: 1,
  currentSection: '',
  certResult: []
};

const emetCertSlice = createSlice({
  name: 'emetCertInfo',
  initialState: emetCertInitialState,
  reducers: {
    setCurrentQuestion(state, action) {
      if (
        action.payload.numberOfQuestion === 88888 ||
        (action.payload.numberOfQuestion === 99999 &&
          state.currentSection !== 'DONE' &&
          state.currentSection !== '')
      ) {
        state.step += 1;
      }
      state.currentQuestion = action.payload;
      state.remainingTime = action.payload.time;
      state.currentSection = action.payload.section;
    },
    setUserAnswer(state, action) {
      state.userAnswer = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    incrementStep(state) {
      state.step += 1;
    },
    setRemainingTime(state, action) {
      state.remainingTime = action.payload;
    },
    decrementRemainingTime(state) {
      state.remainingTime -= 1;
    },
    currentSection(state, action) {
      state.currentSection = action.payload;
    },
    setCertResult(state, action) {
      state.certResult = action.payload;
    },
    resetEmetCert(state) {
      state.currentQuestion = {
        numberOfQuestion: 0,
        instruction: '',
        question: '',
        section: '',
        time: 0,
        answer: []
      };
      state.remainingTime = 0;
    }
  }
});

export const emetCertActions = emetCertSlice.actions;

export default emetCertSlice.reducer;
