// import { team } from 'data/support-desk/quickLinksData';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import emetCertImg from 'assets/img/support-desk/reports/5.png';
import epstCertImg from 'assets/img/support-desk/reports/6.png';
import { Link, useFetcher, useLocation } from 'react-router-dom';
import { emetCertActions } from 'store/emetCert';
import {
  FiEye,
  FiSettings,
  FiClock,
  FiSpeaker,
  FiPlay,
  FiCloudOff

  /*FiSkipForward,
  FiType,
  
  FiCloud,
  FiExternalLink,
  FiCloudDrizzle,
  FiCloudLightning,
  
  FiCloudRain,
  FiCloudSnow
   FiBriefcase,
  FiMic,
  FiGitPullRequest,
  FiRepeat,
  FiUser,
  FiUsers
  FiAlertCircle,
  FiFileText,
  FiHexagon,
  FiLock,
  FiUserCheck,
  FiSunrise, */
} from 'react-icons/fi';
import { useDispatch } from 'react-redux';

const DirectionItem = ({ direction }) => {
  return (
    <Col md={6} xxl={4}>
      <div className="hover-bg-100 py-x1 text-center rounded-3 position-relative">
        <div
          className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-${direction.color}`}
        >
          {direction.icon === 'timer' ? (
            <FiClock className="text-success fs-2" />
          ) : (
            <Fragment />
          )}
          {direction.icon === 'distractions' ? (
            <FiEye className="text-info fs-2" />
          ) : (
            <Fragment />
          )}
          {direction.icon === 'engine' ? (
            <FiSettings className="text-primary fs-2" />
          ) : (
            <Fragment />
          )}
          {direction.icon === 'speaker' ? (
            <FiSpeaker className="text-primary fs-2" />
          ) : (
            <Fragment />
          )}
          {direction.icon === 'play' ? (
            <FiPlay className="text-primary fs-2" />
          ) : (
            <Fragment />
          )}
          {direction.icon === 'lostConnection' ? (
            <FiCloudOff className="text-primary fs-2" />
          ) : (
            <Fragment />
          )}
        </div>
        <h5 className="mt-3 mb-2">
          <a href="#!" className="text-900 hover-primary stretched-link">
            {direction.title}
          </a>
        </h5>
        <h6 className="w-75 mx-auto text-600 mb-x1">{direction.description}</h6>
      </div>
    </Col>
  );
};

DirectionItem.propTypes = {
  direction: PropTypes.shape({
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  })
};

const CertificationItem = ({ cert }) => {
  return (
    <Col lg={6} xxl={6}>
      <Flex
        alignItems="center"
        className="px-4 py-x1 bg-light rounded-3 border position-relative"
      >
        <img
          src={cert.title === 'EPSt' ? epstCertImg : emetCertImg}
          alt=""
          width="39"
        />
        <div className="ms-3 my-x1">
          <h5 className="fs-0 fw-semi-bold mb-2">
            <Link
              to={`/certifications/${
                cert.title === 'EMeT'
                  ? 'emetcertquestions'
                  : 'epstcertquestions'
              }`}
              target="_blank"
              className="text-900 hover-primary stretched-link"
            >
              {`${cert.title} - ${cert.kindCertification}`}
            </Link>
          </h5>
        </div>
      </Flex>
    </Col>
  );
};

CertificationItem.propTypes = {
  cert: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    kindCertification: PropTypes.string.isRequired,
    openCertificateDate: PropTypes.string.isRequired,
    closeCertificateDate: PropTypes.string.isRequired
  })
};

const InitCert = () => {
  const [directions, setDirections] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const [certState /*, setCertState*/] = useState(location.state);
  const fetcher = useFetcher();
  const { data, state } = fetcher;

  useEffect(() => {
    fetcher.submit(
      {
        certification: certState.title,
        trigger: 'GET-CERT-DIRECTIONS'
      },
      { method: 'POST' }
    );
  }, []);

  useEffect(() => {
    if (state === 'idle' && data && data.certDirections) {
      setDirections(data.certDirections);
      fetcher.submit(
        {
          certification: certState.title,
          trigger: 'GENERATE-USER-QUESTIONS'
        },
        { method: 'POST' }
      );
    }
    if (state === 'idle' && data && data.questionsCreated) {
      dispatch(emetCertActions.setStep(1));
    }
  }, [state, data]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="border-bottom border-200">
          <h6 className="mb-0">Indicaciones</h6>
        </Card.Header>
        <Card.Body>
          <Row className="gx-3">
            {directions.map((direction, index) => (
              <DirectionItem key={index} direction={direction} />
            ))}
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="border-bottom border-200">
          <h6 className="mb-0">Empezar certificación</h6>
        </Card.Header>
        <Card.Body>
          <CertificationItem cert={certState} />
        </Card.Body>
      </Card>
    </>
  );
};

export default InitCert;
export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getUserAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-CERT-DIRECTIONS') {
        const cert = data.get('certification');
        const userCert = JSON.stringify({
          certification: cert
        });

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/getCertDirections`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ userCertification: userCert })
          }
        );
      } else if (trigger === 'GENERATE-USER-QUESTIONS') {
        const cert = data.get('certification');
        const userCert = JSON.stringify({
          certification: cert
        });
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/generateQuestions`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ userCertification: userCert })
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};
