import React from 'react';
import Search from '../../doc-components/certifications/Search';
const EpstSearch = () => {
  return (
    <>
      <Search />
    </>
  );
};

export default EpstSearch;

export const loader = async (/* { request, params } */) => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getCertificationsFilterData`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-CERTIFICATIONS') {
        const coleges = data.get('coleges');
        const kindCertification = data.get('kindCertification');
        const certificationCreatedFrom = data.get('certificationCreatedFrom');
        const certificationCreatedTo = data.get('certificationCreatedTo');
        const status = data.get('status');
        const evaluatedBy = data.get('evaluatedBy');

        const certificationFilter = JSON.stringify({
          coleges: coleges,
          kindCertification: kindCertification,
          certificationCreatedFrom: certificationCreatedFrom,
          certificationCreatedTo: certificationCreatedTo,
          status: status,
          evaluatedBy: evaluatedBy
        });

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getCertifications`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ certificationsFilter: certificationFilter })
          }
        );
      } else if (trigger === 'CREATE') {
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/pending/pending`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};
