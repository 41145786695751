import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row /*, Form */, Alert, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from '../../assets/scss/wizard/epstSpeakingForm.module.scss';
import EpstAudioRecorder from './EpstAudioRecorder';

const EpstSpeakingFrom = ({ setError, clearErrors, formState }) => {
  const currentQuestion = useSelector(state => state.epstCert.currentQuestion);
  const speakingAnswered = useSelector(
    state => state.epstCert.speakingAnswered
  );

  return (
    <>
      <Row className={`${styles.instruction}`}>
        <Col>{currentQuestion.instruction}</Col>
      </Row>
      <Row className={`${styles.question} mt-3`}>
        <Col>{currentQuestion.question}</Col>
      </Row>
      <Row className="mt-3">
        <Col className={`${styles.audio}`}>
          <EpstAudioRecorder setError={setError} clearErrors={clearErrors} />
        </Col>
        <Col className={`${styles.colQuestionImage}`}>
          {currentQuestion.image && (
            <Image
              className={`${styles.questionImage}`}
              src={`${process.env.REACT_APP_API_REMOTE_SERVER}${currentQuestion.image}`}
              alt="question image"
            />
          )}
        </Col>
      </Row>
      <Row className={`${styles.errorMessage} mt-5`}>
        <Col>
          {formState.errors.audioUrl && !speakingAnswered && (
            <Alert variant="danger">{formState.errors.audioUrl.message}</Alert>
          )}
          {speakingAnswered && (
            <Alert variant="success">
              Your answer was successfully sent, click button for next question
            </Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

EpstSpeakingFrom.propTypes = {
  setError: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  formState: PropTypes.object.isRequired
};

export default EpstSpeakingFrom;
