import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';

const CurrentCheckedCert = ({ currentCheckedCert }) => {
  const [styleClass] = useState('pt-4 p-xxl-0 pb-0 pe-md-0');
  const [color] = useState('success');

  return (
    <Col
      xxl={3}
      md={6}
      className={`${styleClass} border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0 px-3 text-center`}
    >
      <div className={`icon-circle icon-circle-${color}`}>
        <FontAwesomeIcon
          icon={faClipboardCheck}
          className={`fs-2 text-${color}`}
        />
      </div>
      <h5 className="mb-1 font-sans-serif">
        <span className="fw-normal text-700">
          {' ' + currentCheckedCert.title}
        </span>
      </h5>

      <h5 className="mb-1 mt-1 font-sans-serif">
        {/* <span className="text-500 fw-normal">Preguntas: </span> */}
        <CountUp
          start={0}
          end={currentCheckedCert.count}
          duration={1.75}
          className="text-500"
          separator=","
        />
      </h5>

      {/* <p className="fs--1 fw-semi-bold mb-0">
        {amountLastMonth} <span className="text-600 fw-normal">last month</span>
      </p> */}
    </Col>
  );
};

CurrentCheckedCert.propTypes = {
  currentCheckedCert: PropTypes.object.isRequired
};

export default CurrentCheckedCert;
