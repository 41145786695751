import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import emetLogo from '../../../../assets/img/logos/emet.png';
import SimpleBarReact from 'simplebar-react';

const Emet = () => {
  const [emetData, setEmetData] = useState({});
  const location = useLocation();
  const stateCert = location.state;

  useEffect(() => {
    setEmetData(stateCert);
  }, []);
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="align-items-center text-center mb-3">
            <Col sm={6} className="text-sm-start">
              <img src={emetLogo} alt="invoice" width={150} />
            </Col>
            <Col className="text-sm-end mt-3 mt-sm-0">
              <h2 className="mb-3">Certification</h2>
              <h5>Instituto CertifiED</h5>
              <p className="fs--1 mb-0">
                Hermosillo, Sonora 83140 <br /> México
              </p>
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-500">Certification to</h6>
              <h5>{emetData.name}</h5>
              <p className="fs--1">
                From: {emetData.college}
                <br />
                Birthday: {emetData.birthday}
                <br />
              </p>
              <p className="fs--1">
                <a>{emetData.email}</a>
              </p>
            </Col>
            <Col sm="auto" className="ms-auto">
              <div className="table-responsive">
                <Table borderless size="sm" className="fs--1">
                  <tbody>
                    <tr>
                      <th className="text-sm-end">Certification Id:</th>
                      <td>{emetData.certificationId}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Completion Date:</th>
                      <td>{emetData.finishedCertificationDate}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">TEST:</th>
                      <td>{emetData.test}</td>
                    </tr>
                    <tr className="alert alert-success fw-bold">
                      <th className="text-sm-end">Level:</th>
                      <td>{emetData.level}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <div className="mt-4 fs--1">
            <SimpleBarReact>
              <Table className="border-bottom">
                <thead className="light">
                  <tr className="bg-primary text-white dark__bg-1000">
                    <th className="border-0 text-center">
                      First section: {emetData.section1Title}
                    </th>
                    <th className="border-0 text-center">
                      First section percentage:
                    </th>
                    <th className="border-0 text-center">
                      Second section: {emetData.section2Title}
                    </th>
                    <th className="border-0 text-center">
                      Second section percentage:
                    </th>
                    <th className="border-0 text-center">
                      Third section: {emetData.section2Title}
                    </th>
                    <th className="border-0 text-center">
                      Third section percentage:
                    </th>
                    <th className="border-0 text-center">
                      Fourth section: {emetData.section2Title}
                    </th>
                    <th className="border-0 text-center">
                      Fourth section percentage:
                    </th>
                    <th className="border-0 text-center">Certificate score</th>
                    <th className="border-0 text-center">Total percentage</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle text-center">
                      {emetData.section1Points}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section1Percentage}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section2Points}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section2Percentage}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section3Points}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section3Percentage}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section4Points}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.section4Percentage}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.totalScore}
                    </td>
                    <td className="align-middle text-center">
                      {emetData.totalPercentage}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </SimpleBarReact>
          </div>
        </Card.Body>
        <Card.Footer className="bg-light">
          <p className="fs--1 mb-0">
            <strong>Instituto CerttifiED: </strong> {emetData.description}
          </p>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Emet;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getUserAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};
