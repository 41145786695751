import React from 'react';
import EmetEvaluatedCert from '../../doc-components/certifications/EmetEvaluatedCert';
const EmetEvaluatedDownload = () => {
  return (
    <>
      <EmetEvaluatedCert />
    </>
  );
};

export default EmetEvaluatedDownload;

export const loader = async (/* { request, params } */) => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getEmetCertificationsFilterData`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-EMET-EVALUATED') {
        const coleges = data.get('coleges');
        const email = data.get('email');

        const certificationCreatedFrom = data.get('certificationCreatedFrom');
        const certificationCreatedTo = data.get('certificationCreatedTo');

        const certificationFilter = JSON.stringify({
          coleges: coleges,
          email: email,
          certificationCreatedFrom: certificationCreatedFrom,
          certificationCreatedTo: certificationCreatedTo
        });

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getEmetEvaluatedCertifications`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ certificationsFilter: certificationFilter })
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};
