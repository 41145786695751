import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import FileEmetUploader from './FileEmetUploader';
import FalconComponentCard from 'components/common/FalconComponentCard';

const UploadEmetCert = () => {
  const [validEmetQuestions, setValidEmetQuestions] = useState([]);
  const [inValidEmetQuestions, setInValidEmetQuestions] = useState([]);

  const invalidEmetRows = inValidEmetQuestions.map(row => {
    return `<tr className="table-danger">
                <td>${row.numberOfQuestion}</td>
                <td>${row.section}</td>
                <td>${row.message}</td>
            </tr>`;
  });

  const invalidEmetTable = `<Table responsive>
    <thead>
      <tr>
        <th scope="col">Número de pregunta</th>
        <th scope="col">Sección</th>
        <th scope="col">Mensaje</th>
      </tr>
    </thead>
    <tbody>
    ${invalidEmetRows.join('')}

    </tbody>
  </Table>`;

  const validEmetRows = validEmetQuestions.map(row => {
    return `<tr className="table-success">
              <td>${row.numberOfQuestion}</td>
              <td>${row.section}</td>
              <td>${row.message}</td>
          </tr>`;
  });

  const validEmetTable = `<Table responsive>
    <thead>
      <tr>
        <th scope="col">Número de pregunta</th>
        <th scope="col">Sección</th>
        <th scope="col">Mensaje</th>
      </tr>
    </thead>
    <tbody>
    ${validEmetRows.join('')}

    </tbody>
  </Table>`;

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="align-items-center justify-content-between">
            <Col lg={3}>
              <img src={editing} className="img-fluid" alt="" />
            </Col>
            <Col lg={9} className="ps-lg-4 my-5 text-center text-lg-start">
              <h4 className="text-primary">Cargar Certificación EMeT</h4>
              <FileEmetUploader
                setValidEmetQuestions={setValidEmetQuestions}
                setInValidEmetQuestions={setInValidEmetQuestions}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {inValidEmetQuestions.length > 0 && (
        <Row>
          <Col lg={12} className="my-3 text-center text-lg-start">
            <FalconComponentCard noGuttersBottom className="h-100">
              <FalconComponentCard.Header
                title={`${inValidEmetQuestions.length} preguntas EMeT no creadas`}
                noPreview
              >
                <p className="mt-2 mb-0">
                  Revise el motivo y valide el archivo XLSX para corregir los
                  errores.
                </p>
              </FalconComponentCard.Header>
              <FalconComponentCard.Body
                code={invalidEmetTable}
                language="jsx"
                // scope={{ ActionButton }}
                // noLight
                className="py-0"
              />
            </FalconComponentCard>
          </Col>
        </Row>
      )}

      {validEmetQuestions.length > 0 && (
        <Row>
          <Col lg={12} className="my-3 text-center text-lg-start">
            <FalconComponentCard noGuttersBottom className="h-100">
              <FalconComponentCard.Header
                title={`${validEmetQuestions.length} preguntas EMeT actualizadas o creadas`}
                noPreview
              >
                <p className="mt-2 mb-0">
                  Estas preguntas se crearon satisfactoriamente.
                </p>
              </FalconComponentCard.Header>
              <FalconComponentCard.Body
                code={validEmetTable}
                language="jsx"
                // scope={{ ActionButton }}
                // noLight
                className="py-0"
              />
            </FalconComponentCard>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UploadEmetCert;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getAdminAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};
