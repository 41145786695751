// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import classNames from 'classnames';
// import { getProductsQuantity } from 'helpers/utils';
import React from 'react';
import { Nav, Row } from 'react-bootstrap';
import styles from '../../../assets/scss/navbar/top/userInfo.module.scss';
import { useSelector } from 'react-redux';

const UserInfo = () => {
  const userName = useSelector(state => state.user.userInfo.userName);

  return (
    <Nav.Item as="li" className="d-none d-sm-block">
      <Nav.Link>
        <Row className={styles.welcome}>
          <span className={`${styles.centerText} text-600 fw-normal`}>
            Bienvenido
          </span>
        </Row>
        <Row>
          <span className={`${styles.centerText} text-600 fw-normal`}>
            {userName}
          </span>
        </Row>
      </Nav.Link>
    </Nav.Item>
  );
};

export default UserInfo;
