import React from 'react';
import { useRouteError } from 'react-router-dom';

import RootError404 from './RootError404';
import RootError500 from './RootError500';
import RootError401 from './RootError401';
import RootError409 from './RootError409';

const ErrorRouterHandler = () => {
  const error = useRouteError();

  let error401State = {
    status: '',
    title: '',
    message: '',
    footerMessage: ``
  };

  let error404State = {
    status: '',
    title: '',
    message: '',
    footerMessage: ``
  };
  let error409State = {
    status: '',
    title: '',
    message: '',
    footerMessage: ``
  };
  let error500State = {
    status: '',
    title: '',
    message: '',
    footerMessage: ``
  };

  switch (error.status) {
    case 401:
      error401State.status = error.status;
      error401State.title = error.data.title;
      error401State.message = error.data.message;
      error401State.footerMessage = error.data.footerMessage;
      break;
    case 404:
      error404State.status = error.status;
      error404State.title = 'La página que estas buscando no existe.';
      error404State.message = `Asegúrate de que la ruta que proporcionaste sea correcta o que la
      página no se haya movido.`;
      error404State.footerMessage = 'ir a login';
      break;
    case 409:
      error409State.status = error.status;
      error409State.title = error.data.title;
      error409State.message = error.data.message;
      error409State.footerMessage = error.data.footerMessage;
      break;
    case 500:
      error500State.status = error.status;
      error500State.title = 'Whoops, algo salió mal!';
      error500State.message =
        'Intenta refrescar la pagina o regresa a intentarlo de nuevo. si el problema persiste,';
      error500State.footerMessage = 'ir a login';
      break;
  }

  return (
    <>
      {error.status === 401 && <RootError401 error={error401State} />}
      {error.status === 404 && <RootError404 error={error404State} />}
      {error.status === 409 && <RootError409 error={error409State} />}
      {error.status === 500 && <RootError500 error={error500State} />}
    </>
  );
};

export default ErrorRouterHandler;
