import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Alert, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from '../../assets/scss/wizard/epstGrammarForm.module.scss';

const EpstGrammarFrom = ({ register, errors }) => {
  const currentQuestion = useSelector(state => state.epstCert.currentQuestion);
  const answerOptions = useSelector(
    state => state.epstCert.currentQuestion.answer
  );

  return (
    <>
      <Row className={`${styles.instruction}`}>
        <Col>{currentQuestion.instruction}</Col>
      </Row>
      <Row className={`${styles.question} mt-3`}>
        <Col>{currentQuestion.question}</Col>
        <Col className={`${styles.colQuestionImage}`}>
          {currentQuestion.image && (
            <Image
              className={`${styles.questionImage}`}
              src={`${process.env.REACT_APP_API_REMOTE_SERVER}${currentQuestion.image}`}
              alt="question image"
            />
          )}
        </Col>
      </Row>
      <Row className={`${styles.answerOptions} mt-3`}>
        {answerOptions.map(answerOption => (
          <Col key={answerOption}>
            <div className="mb-2">
              <Form.Check type="radio" id={answerOption}>
                <Form.Check.Input
                  type="radio"
                  // onClick={e => handleRadioChange(e)}
                  {...register('userAnswer', {
                    required: 'Select any of the options before continue.'
                  })}
                  value={answerOption}
                  isInvalid={errors['userAnswer']}
                  isValid={
                    Object.keys(errors).length > 0 && !errors['userAnswer']
                  }
                />
                <Form.Check.Label className="ms-2">
                  {answerOption}
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Col>
        ))}
      </Row>
      <Row className={`${styles.errorMessage} mt-5`}>
        <Col>
          {errors['userAnswer'] && (
            <Alert variant="danger">{errors['userAnswer']?.message}</Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

EpstGrammarFrom.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default EpstGrammarFrom;
