import EpstAudioPlayer from 'components/wizard/EpstAudioPlayer';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tabs, Tab, Button, Container } from 'react-bootstrap';
import { useFetcher } from 'react-router-dom';
// import editing from 'assets/img/icons/spot-illustrations/21.png';
import styles from '../../../assets/scss/pages/certifications/ExamineCert/examineCert.module.scss';
const ExamineCert = () => {
  const [key, setKey] = useState('writing');
  const [writings, setWritings] = useState([]);
  const [speakings, setSpeakings] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [writingInputValues, setWritingInputValues] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [speakingInputValues, setSpeakingInputValues] = useState({});
  const writingRefs = {};
  const speakingRefs = {};

  // Access the query parameters from the URL
  const fetcher = useFetcher();
  const { data, state } = fetcher;

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const userType = searchParams.get('userType');
  const kindCertification = searchParams.get('kindCertification');

  const handleEvaluateWriting = (
    email,
    userType,
    kindCertification,
    numberOfQuestion,
    points
  ) => {
    const scoreReceived = writingRefs[numberOfQuestion].value;
    if (scoreReceived === '') {
      return;
    }
    const score =
      scoreReceived === '' ? 0 : (parseInt(scoreReceived, 10) / 100) * points;
    fetcher.submit(
      {
        email: JSON.stringify(email),
        userType: JSON.stringify(userType),
        kindCertification: JSON.stringify(kindCertification),
        numberOfQuestion: JSON.stringify(numberOfQuestion),
        score: JSON.stringify(Math.round(score)),
        trigger: 'EVALUATE-WRITING'
      },
      { method: 'POST' }
    );

    // Update the input value in state
    setWritingInputValues(prevInputValues => ({
      ...prevInputValues,
      [numberOfQuestion]: score
    }));
  };

  const handleEvaluateSpeaking = (
    email,
    userType,
    kindCertification,
    numberOfQuestion,
    points
  ) => {
    const scoreReceived = speakingRefs[numberOfQuestion].value;
    if (scoreReceived === '') {
      return;
    }
    const score =
      scoreReceived === '' ? 0 : (parseInt(scoreReceived, 10) / 100) * points;

    fetcher.submit(
      {
        email: JSON.stringify(email),
        userType: JSON.stringify(userType),
        kindCertification: JSON.stringify(kindCertification),
        numberOfQuestion: JSON.stringify(numberOfQuestion),
        score: JSON.stringify(Math.round(score)),
        trigger: 'EVALUATE-SPEAKING'
      },
      { method: 'POST' }
    );

    // Update the input value in state
    setSpeakingInputValues(prevInputValues => ({
      ...prevInputValues,
      [numberOfQuestion]: score
    }));
  };

  useEffect(() => {
    fetcher.submit(
      {
        email: JSON.stringify(email),
        userType: JSON.stringify(userType),
        kindCertification: JSON.stringify(kindCertification),
        trigger: 'GET-QUESTIONS-TO-EXAMINE'
      },
      { method: 'POST' }
    );
  }, []);

  useEffect(() => {
    if (state == 'idle' && data && data.writingQuestions) {
      setWritings(data.writingQuestions);
    }
    if (state == 'idle' && data && data.speakingQuestions) {
      setSpeakings(data.speakingQuestions);
    }
    if (state == 'idle' && data && data.writingUpdated) {
      const { numberOfQuestion } = data;
      const removedWritingUpdated = writings.filter(
        row => row.numberOfQuestion !== numberOfQuestion
      );
      setWritings(removedWritingUpdated);
    }
    if (state == 'idle' && data && data.speakingUpdated) {
      const { numberOfQuestion } = data;
      const removedSpeakingUpdated = speakings.filter(
        row => row.numberOfQuestion !== numberOfQuestion
      );
      setSpeakings(removedSpeakingUpdated);
    }
  }, [data, state]);

  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <h5 className="text-primary">Calificar Certificación</h5>
        <Row className={`mt-3 ${styles.rowUserData}`}>
          <Col xs={6} className={`${styles.firstColUserData}`}>
            Email:
          </Col>
          <Col xs={6}>{email}</Col>
        </Row>
        <Row className={`${styles.rowUserData}`}>
          <Col xs={6} className={`${styles.firstColUserData}`}>
            Tipo usuario:
          </Col>
          <Col xs={6}>{userType}</Col>
        </Row>
        <Row className={`${styles.rowUserData}`}>
          <Col xs={6} className={`${styles.firstColUserData}`}>
            Tipo certificación:
          </Col>
          <Col xs={6}>{kindCertification}</Col>
        </Row>
        <Tabs
          defaultActiveKey="writing"
          activeKey={key}
          onSelect={k => setKey(k)}
          className="mb-3 mt-3"
        >
          <Tab eventKey="writing" title="Writing" className="border p-3">
            {writings.length <= 0 && (
              <Row>
                <Col>
                  Ya se calificaron todas las respuestas de esta categoría
                </Col>
              </Row>
            )}
            {writings.length > 0 &&
              writings.map(userAnswer => {
                return (
                  <Container key={userAnswer.numberOfQuestion}>
                    <Row className={`mt-2 ${styles.rowAnswer}`}>
                      <Col
                        xs={2} // 0 al 767
                        md={2} // 768 al 991
                        lg={2} // 992 al 1199
                        xl={2} // 1200 al 1539
                        className={`${styles.colLevel}`}
                      >
                        {userAnswer.level}
                      </Col>
                      <Col
                        xs={10}
                        md={10}
                        lg={10}
                        xl={10}
                        className={`${styles.colQuestion}`}
                      >
                        {userAnswer.question}
                      </Col>

                      <Col
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={`${styles.colAnswer}`}
                      >
                        {userAnswer.answer}
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        lg={3}
                        xl={3}
                        className={`${styles.colScore}`}
                      >
                        <input
                          ref={ref =>
                            (writingRefs[userAnswer.numberOfQuestion] = ref)
                          }
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          defaultValue={0}
                          onInput={e => {
                            const value = parseInt(e.target.value, 10);

                            if (isNaN(value) || value < 0) {
                              e.target.value = '0';
                            } else if (value > 100) {
                              e.target.value = '100';
                            }
                          }}
                        />
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        lg={3}
                        xl={3}
                        className={`${styles.colCalificar}`}
                      >
                        <Button
                          className={`${styles.btnCalificar}`}
                          onClick={() =>
                            handleEvaluateWriting(
                              email,
                              userType,
                              kindCertification,
                              userAnswer.numberOfQuestion,
                              userAnswer.points
                            )
                          }
                        >
                          Calificar
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                );
              })}
          </Tab>
          <Tab eventKey="speaking" title="Speaking" className="border p-3">
            {speakings.length <= 0 && (
              <Row>
                <Col>
                  Ya se calificaron todas las respuestas de esta categoría
                </Col>
              </Row>
            )}
            {speakings.length > 0 &&
              speakings.map(userAnswer => {
                return (
                  <Container key={userAnswer.numberOfQuestion}>
                    <Row className={`mt-2 `}>
                      <Col
                        xs={2} // 0 al 767
                        md={2} // 768 al 991
                        lg={2} // 992 al 1199
                        xl={2} // 1200 al 1539
                        /*xxl={5} // 1540 en adelante */
                        className={`${styles.colLevel}`}
                      >
                        {userAnswer.level}
                      </Col>
                      <Col
                        xs={10}
                        md={10}
                        lg={10}
                        xl={10}
                        className={`${styles.colQuestion}`}
                      >
                        {userAnswer.question}
                      </Col>

                      <Col
                        xs={12}
                        md={12}
                        lg={6}
                        xl={6}
                        className={`${styles.colAnswer}`}
                      >
                        <EpstAudioPlayer audioSrc={userAnswer.answer} />
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        lg={3}
                        xl={3}
                        className={`${styles.colScore}`}
                      >
                        <input
                          className={`${styles.inputCalificar}`}
                          ref={ref =>
                            (speakingRefs[userAnswer.numberOfQuestion] = ref)
                          }
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          defaultValue={0}
                          onInput={e => {
                            const value = parseInt(e.target.value, 10);

                            if (isNaN(value) || value < 0) {
                              e.target.value = '0';
                            } else if (value > 100) {
                              e.target.value = '100';
                            }
                          }}
                        />
                      </Col>
                      <Col
                        xs={6}
                        md={6}
                        lg={3}
                        xl={3}
                        className={`${styles.colCalificar}`}
                      >
                        <Button
                          className={`${styles.btnCalificar}`}
                          onClick={() =>
                            handleEvaluateSpeaking(
                              email,
                              userType,
                              kindCertification,
                              userAnswer.numberOfQuestion,
                              userAnswer.points
                            )
                          }
                        >
                          Calificar
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                );
              })}
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default ExamineCert;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getExaminerAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-QUESTIONS-TO-EXAMINE') {
        const email = data.get('email');
        const userType = data.get('userType');
        const kindCertification = data.get('kindCertification');

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/examiner/getQuestionsToExamine`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
              email: email,
              userType: userType,
              kindCertification: kindCertification
            })
          }
        );
      } else if (trigger === 'EVALUATE-WRITING') {
        const email = data.get('email');
        const userType = data.get('userType');
        const kindCertification = data.get('kindCertification');
        const numberOfQuestion = data.get('numberOfQuestion');
        const score = data.get('score');

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/examiner/evaluateWriting`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
              email: email,
              userType: userType,
              kindCertification: kindCertification,
              numberOfQuestion: numberOfQuestion,
              score: score
            })
          }
        );
      } else if (trigger === 'EVALUATE-SPEAKING') {
        const email = data.get('email');
        const userType = data.get('userType');
        const kindCertification = data.get('kindCertification');
        const numberOfQuestion = data.get('numberOfQuestion');
        const score = data.get('score');

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/examiner/evaluateSpeaking`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
              email: email,
              userType: userType,
              kindCertification: kindCertification,
              numberOfQuestion: numberOfQuestion,
              score: score
            })
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};
