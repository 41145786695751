import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';

const EmetDash = ({ emet }) => {
  const [styleClass] = useState('pt-4 p-xxl-0 pb-0 pe-md-0');
  const [color] = useState('info');

  return (
    <Col
      xxl={3}
      md={6}
      className={`${styleClass} pt-4 p-xxl-0 pb-0 pe-md-0 px-3 text-center`}
    >
      <div className={`icon-circle icon-circle-${color}`}>
        <FontAwesomeIcon icon={faBook} className={`fs-2 text-${color}`} />
      </div>
      <h5 className="mb-1 font-sans-serif">
        <span className="fw-normal text-700">{' ' + emet.certName}</span>
      </h5>
      <h6 className="mb-2 font-sans-serif">
        <span className="fw-normal text-700">{' ' + emet.certDesc}</span>
      </h6>
      <h6 className="mb-2 font-sans-serif">
        <span className="fw-normal text-500">
          {'Actualizado: ' + emet.lastUpdate}
        </span>
      </h6>

      <h6 className="mb-1 mt-1 font-sans-serif">
        <span className="text-500 fw-normal">Preguntas: </span>
        <CountUp
          start={0}
          end={emet.questions}
          duration={1.75}
          className="text-500"
          separator=","
        />
      </h6>

      {/* <p className="fs--1 fw-semi-bold mb-0">
        {amountLastMonth} <span className="text-600 fw-normal">last month</span>
      </p> */}
    </Col>
  );
};

EmetDash.propTypes = {
  emet: PropTypes.object.isRequired
};

export default EmetDash;
