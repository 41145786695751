import React from 'react';
// import { lmsStatistics } from 'data/dashboard/lms';
// import LmsStats from './lms-stats/LmsStats';
import UserDashboard from './UserDashboard';
import UserPendingCertification from './UserPendingCertification';
import UserDoneCertification from './UserDoneCertification';
import { useSelector } from 'react-redux';

const Lms = () => {
  const userRole = useSelector(state => state.user.userInfo.userRole);
  return (
    <>
      {userRole !== 'candidate' && <UserDashboard />}
      <UserDoneCertification />
      <UserPendingCertification />
    </>
  );
};

export default Lms;

export async function loader() {
  const dashboarDataReq = await fetch(
    `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/dashboardData`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }
  );

  if (!dashboarDataReq.ok) {
    throw dashboarDataReq;
  }
  return dashboarDataReq;
}

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-EXAMINER-PENDING-CERT') {
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/certifications/getPendigToEvaluateCertification`,
          {
            method: 'GET',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      } else if (trigger === 'DELETE-EMET-USER-QUESTIONS') {
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/deleteEmetQuestions`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      } else if (trigger === 'DELETE-EPST-USER-QUESTIONS') {
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/user/deleteEpstQuestions`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};
