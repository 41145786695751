import React, { useEffect } from 'react';
// import { reports } from 'data/support-desk/quickLinksData';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { useLoaderData } from 'react-router-dom';
import emetCertImg from 'assets/img/support-desk/reports/5.png';
import epstCertImg from 'assets/img/support-desk/reports/6.png';
import { useFetcher } from 'react-router-dom';

import styles from '../../../assets/scss/dashboard/lms/userDoneCertification.module.scss';
const CertificationItem = ({ cert }) => {
  return (
    <Col lg={6} xxl={6}>
      <Flex
        alignItems="center"
        className="px-4 py-x1 bg-light rounded-3 border position-relative"
      >
        <img
          src={cert.title === 'EPSt' ? epstCertImg : emetCertImg}
          alt=""
          width="39"
        />
        <div className="ms-3 my-x1">
          <h5 className="fs-0 fw-semi-bold mb-2">
            {cert.title} - {cert.description}
          </h5>

          <h6 className="mb-0 text-600 mt-2 mb-3">{`${cert.leyend}`}</h6>
          {/* <h6 className="mb-0 text-600">{cert.description}</h6> */}

          <h6 className="mb-0 text-600 mt-1">
            <span className={`${styles.boldTitle}`}>Tipo:</span>
            {`${cert.kindCertification}`}
          </h6>

          {cert.title === 'EPSt' && (
            <>
              <h6 className="mb-0 text-600 mt-1">
                <span className={`${styles.boldTitle}`}>Tipo candidato:</span>
                {`${cert.userType}`}
              </h6>

              {cert.evaluatedBy && (
                <h6 className="mb-0 text-600 mt-1">
                  <span className={`${styles.boldTitle}`}>Evaluado por:</span>
                  {`${cert.evaluatedBy}`}
                </h6>
              )}

              {cert.finishedEvaluatedDate && (
                <h6 className="mb-0 text-600 mt-1">
                  <span className={`${styles.boldTitle}`}>Evaluado en:</span>
                  {`${cert.finishedEvaluatedDate}`}
                </h6>
              )}
            </>
          )}
        </div>
      </Flex>
    </Col>
  );
};

CertificationItem.propTypes = {
  cert: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    kindCertification: PropTypes.string.isRequired,
    evaluatedBy: PropTypes.string.isRequired,
    leyend: PropTypes.string.isRequired,
    finishedEvaluatedDate: PropTypes.string.isRequired,
    openCertificateDate: PropTypes.string.isRequired,
    closeCertificateDate: PropTypes.string.isRequired
  })
};

const UserDoneCertification = () => {
  const fetcher = useFetcher();

  // const handleDeleteEpstQuestions = () => {
  //   fetcher.submit(
  //     {
  //       trigger: 'DELETE-EPST-USER-QUESTIONS'
  //     },
  //     { method: 'POST' }
  //   );
  // };

  // const handleDeleteEmetQuestions = () => {
  //   fetcher.submit(
  //     {
  //       trigger: 'DELETE-EMET-USER-QUESTIONS'
  //     },
  //     { method: 'POST' }
  //   );
  // };
  const backendDashboardData = useLoaderData();
  const userData = backendDashboardData.dashboardData;

  const { data, state } = fetcher;

  useEffect(() => {
    if (state === 'idle' && data && data.wasDeltedQuestions) {
      const wasDeletedQuestions = data.wasDeltedQuestions;
      console.log(wasDeletedQuestions);
    }
  }, [state, data]);

  return (
    <>
      {userData.doneCertifications.length > 0 && (
        <Card className="mb-3">
          <Card.Header className="bg-light">
            <h5 className="mb-0">Tus certificaciones realizadas</h5>
          </Card.Header>
          <Card.Body>
            {/* <Row>
              <Col>
                <Button onClick={handleDeleteEmetQuestions}>
                  Borrar Preguntas Emet
                </Button>
              </Col>
              <Col>
                <Button onClick={handleDeleteEpstQuestions}>
                  Borrar Preguntas Epst
                </Button>
              </Col>
            </Row> */}
            <Row className="g-3">
              {userData.doneCertifications.map((cert, index) => (
                <CertificationItem key={index} cert={cert} />
              ))}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default UserDoneCertification;
