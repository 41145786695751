import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { emetCertActions } from 'store/emetCert';
import { useFetcher } from 'react-router-dom';
import styles from '../../assets/scss/wizard/emetBreak.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EmetBreak = () => {
  const dispatch = useDispatch();
  const fetcher = useFetcher();
  const { data, state } = fetcher;
  const remainingTime = useSelector(state => state.emetCert.remainingTime);
  const currentQuestion = useSelector(state => state.emetCert.currentQuestion);

  const sendAnswer = answer => {
    const numberOfQuestion = currentQuestion.numberOfQuestion;

    if (numberOfQuestion === 88888) {
      getNextQuestion();
    } else {
      fetcher.submit(
        {
          trigger: 'POST-ANSWER-QUESTION',
          selectedAnswer: JSON.stringify(answer),
          numberOfQuestion: numberOfQuestion
        },
        { method: 'POST' }
      );
    }
  };

  const getNextQuestion = () => {
    fetcher.submit(
      {
        trigger: 'GET-NEXT-QUESTION'
      },
      { method: 'POST' }
    );
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      if (remainingTime > 0) {
        dispatch(emetCertActions.decrementRemainingTime());
      } else if (remainingTime <= 0) {
        sendAnswer(null);
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [remainingTime]);

  useEffect(() => {
    if (state === 'idle' && data && data.nextQuestion) {
      const nextQuest = data.nextQuestion[0];
      dispatch(emetCertActions.incrementStep());
      dispatch(emetCertActions.setCurrentQuestion(nextQuest));
      dispatch(emetCertActions.setRemainingTime(nextQuest.time));
    } else if (state === 'idle' && data && data.wasQuestionAnswerUpdate) {
      const wasQuestionAnswerUpdate = data.wasQuestionAnswerUpdate;
      if (wasQuestionAnswerUpdate) {
        getNextQuestion();
      }
    }
  }, [state, data]);

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <Col lg={12} className="text-center">
          <FontAwesomeIcon size="lg" icon={'clock'} />
          {currentQuestion.section !== 'DONE' && (
            <p className={`${styles.countDownMessage}`}>
              Break time <span>{remainingTime}</span> sec
            </p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default EmetBreak;
