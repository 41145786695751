import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from '../../assets/scss/wizard/epstWritingForm.module.scss';

const EpstWitingFrom = ({ register, errors }) => {
  const [wordCount, setWordCount] = useState(0);
  // const [userAnswer, setUserAnswer] = useState(''); // Controlled input
  const currentQuestion = useSelector(state => state.epstCert.currentQuestion);

  const handleTextareaChange = event => {
    const text = event.target.value;
    // setUserAnswer(text); // Update the state with the current input
    // Split the text into words and filter out empty strings
    const words = text.split(/\s+/).filter(word => word !== '');
    setWordCount(words.length);
  };

  // Event handler to prevent copy and paste actions
  const handleCopyPaste = e => {
    e.preventDefault();
  };

  useEffect(() => {
    // When currentQuestion.numberOfQuestion changes, clear the text area
    const textareaElement = document.getElementById('candidateText');
    if (textareaElement) {
      textareaElement.value = '';
    }
  }, [currentQuestion.numberOfQuestion]);

  return (
    <>
      <Row className={`${styles.instruction}`}>
        <Col>{currentQuestion.instruction}</Col>
      </Row>
      <Row className={`${styles.question} mt-3`}>
        <Col>{currentQuestion.question}</Col>
      </Row>
      <Row className={`${styles.answerOptions} mt-3`}>
        <Col>
          <Form.Group>
            <Form.Control
              className={`${styles.writeText}`}
              id="candidateText"
              as="textarea"
              {...register('userAnswer', {
                required: 'Answer the question before continue.'
              })}
              placeholder={'Answer the question'}
              isValid={Object.keys(errors).length > 0 && !errors['userAnswer']}
              isInvalid={errors['userAnswer']}
              rows={17}
              onChange={handleTextareaChange}
              onCopy={handleCopyPaste}
              onCut={handleCopyPaste}
              onPaste={handleCopyPaste}
            />
            {/* <Form.Control.Feedback type="invalid">
                {errors['userAnswer']?.message}
              </Form.Control.Feedback> */}
          </Form.Group>
          <span className={`mt-1 ${styles.countWords}`}>{wordCount} words</span>
        </Col>
      </Row>
      <Row className={`${styles.errorMessage} mt-5`}>
        <Col>
          {errors['userAnswer'] && (
            <Alert variant="danger">{errors['userAnswer']?.message}</Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

EpstWitingFrom.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default EpstWitingFrom;
