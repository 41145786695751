import React, { useEffect, useState } from 'react';
import { Card, Row, Spinner, Alert } from 'react-bootstrap';
// import LmsStatItem from './LmsStatItem';
import styles from '../../../assets/scss/dashboard/lms/index.module.scss';
import { useLoaderData, useNavigation, useFetcher } from 'react-router-dom';
import EpstDash from './lms-stats/admin/EpstDash';
import EmetDash from './lms-stats/admin/EmetDash';
import { useSelector } from 'react-redux';
import LastCert from './lms-stats/examiner/LastCert';
import PendingCert from './lms-stats/examiner/PendingCert';
import CurrentCheckedCert from './lms-stats/examiner/CurrentCheckedCert';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

const UserDashboard = () => {
  const userRole = useSelector(state => state.user.userInfo.userRole);
  const backendDashboardData = useLoaderData();
  const navigation = useNavigation();
  const userData = backendDashboardData.dashboardData;
  const [pendingCerts, setPendingCerts] = useState([]);
  const fetcher = useFetcher();
  const { data, state } = fetcher;

  const pendingCertUsersTableCode = `
        const columns = [
          {
            accessor: 'name',
            Header: 'Nombre'
          },
          {
            accessor: 'email',
            Header: 'Correo',
            Cell: rowData => {
              const { email, userType, kindCertification } = rowData.row.original;
              const href = \`/certifications/examine?email=\${email}&userType=\${userType}&kindCertification=\${kindCertification}\`;

              return ( 
                  <a
                 href={href}
                 target="_blank"
              >
                {email}
              </a> 
              );
            }
          },
          {
            accessor: 'college',
            Header: 'Colegio'
          },
          {
            accessor: 'userType',
            Header: 'Tipo'
          },
          {
            accessor: 'kindCertification',
            Header: 'Tipo Cert.'
          },
          {
            accessor: 'status',
            Header: 'Estatus'
          }
        ];
      
        
        const pendingCerts = [
          
          ${pendingCerts.map(cert => {
            return `{name: '${cert.name}', email: '${cert.email}',  college: '${cert.college}', userType: '${cert.userType}', kindCertification:'${cert.kindCertification}', status: '${cert.status}' }`;
          })}
      
           ];
    
      
           function PendingEvaluateTable() {
            return (
              <AdvanceTableWrapper
                columns={columns}
                data={pendingCerts}
                sortable
                pagination
                perPage={5}
              >
                <Row className="flex-end-center mb-3">
                  <Col xs="auto" sm={6} lg={4}>
                    <AdvanceTableSearchBox table />
                  </Col>
                </Row>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={pendingCerts.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
              </AdvanceTableWrapper>
            );
          }
          
      render(<PendingEvaluateTable />)`;

  useEffect(() => {
    fetcher.submit(
      {
        trigger: 'GET-EXAMINER-PENDING-CERT'
      },
      { method: 'POST' }
    );
  }, []);

  useEffect(() => {
    if (state == 'idle' && data && data.pendingToEvaluate) {
      setPendingCerts(data.pendingToEvaluate);
    }
  }, [data, state]);

  return (
    <>
      <Card
        className={`mb-3 ${
          navigation.state === 'loading' ? styles.spinnerLoading : ''
        }`}
      >
        <Card.Body className="px-xxl-0 pt-4">
          {navigation.state === 'loading' && (
            <Spinner animation="border" role="status"></Spinner>
          )}
          {navigation.state !== 'loading' && (
            <Row className="g-0">
              {userRole === 'admin' && (
                <>
                  <EpstDash epst={userData.admin.dashboard.epstDash} />
                  <EmetDash emet={userData.admin.dashboard.emetDash} />
                  {/* <Col style={{ textAlign: 'center' }}>User:{userData.user}</Col>
                <Col style={{ textAlign: 'center' }}>Rol:{userData.role}</Col> */}
                </>
              )}
              {userRole === 'examiner' && (
                <>
                  <LastCert
                    lastCheckedCert={
                      userData.examiner.dashboard.lastCheckedCert
                    }
                  />
                  <PendingCert
                    pendingCert={userData.examiner.dashboard.pendingCert}
                  />
                  <CurrentCheckedCert
                    currentCheckedCert={
                      userData.examiner.dashboard.currentCheckedCert
                    }
                  />
                </>
              )}
              {/* {userRole === 'candidate' && (
                // <UserDoneCertification />
                //  <Col style={{ textAlign: 'center' }}>
                //   User:{userData.user}
                // </Col>
                // <Col style={{ textAlign: 'center' }}>Rol:{userData.role}</Col>
                //
              )} */}
            </Row>
          )}
        </Card.Body>
      </Card>
      {userRole !== 'candidate' && pendingCerts.length > 0 && (
        <FalconComponentCard>
          <FalconComponentCard.Header
            title="Certificaciones EPSt pendientes por evaluar"
            light={false}
            className="border-bottom border-200"
            noPreview
          ></FalconComponentCard.Header>

          <FalconComponentCard.Body
            code={pendingCertUsersTableCode}
            scope={{
              AdvanceTableWrapper,
              AdvanceTable,
              AdvanceTableFooter,
              AdvanceTableSearchBox
            }}
            language="jsx"
            noInline
            noLight
          />
        </FalconComponentCard>
      )}
      {userRole === 'examiner' && pendingCerts.length <= 0 && (
        <Card className="mb-3">
          <Card.Body>
            <Alert variant="success">
              <p className="mb-0">
                Sin certificaciones pendientes por calificar!
              </p>
            </Alert>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default UserDashboard;
