import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Error401Router = ({ error }) => {
  return (
    <Card className="text-center h-100">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">
          {error.status ? error.status : 500}
        </div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {error.title ? error.title : 'Whoops, something went wrong!'}
        </p>
        <hr />
        <p>
          {error.message
            ? error.message
            : `Try refreshing the page, or going back and attempting the action
            again. If this problem persists,`}
          <Link to="/" className="ms-1">
            {error.footerMessage ? error.footerMessage : `login`}
          </Link>
          .
        </p>
      </Card.Body>
    </Card>
  );
};

Error401Router.propTypes = {
  error: PropTypes.object.isRequired
};

export default Error401Router;
