import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import Error401Router from './Error401Router';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
const RootError401 = ({ error }) => {
  const routeNavigation = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      routeNavigation('/');
    }, 3000);
  });
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Logo width={120} />
          <Error401Router error={error} />
        </Col>
      </Row>
    </Section>
  );
};

RootError401.propTypes = {
  error: PropTypes.object.isRequired
};

export default RootError401;
