import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import editing from 'assets/img/icons/spot-illustrations/21.png';
import FileEpstUploader from './FileEpstUploader';
import FalconComponentCard from 'components/common/FalconComponentCard';

const UploadEpstCert = () => {
  const [validEpstQuestions, setValidEpstQuestions] = useState([]);
  const [inValidEpstQuestions, setInValidEpstQuestions] = useState([]);

  const invalidEpstRows = inValidEpstQuestions.map(row => {
    return `<tr className="table-danger">
                <td>${row.numberOfQuestion}</td>
                <td>${row.section}</td>
                <td>${row.level}</td>
                <td>${row.message}</td>
            </tr>`;
  });

  const invalidEpstTable = `<Table responsive>
    <thead>
      <tr>
        <th scope="col">Número de pregunta</th>
        <th scope="col">Sección</th>
        <th scope="col">Nivel</th>
        <th scope="col">Mensaje</th>
      </tr>
    </thead>
    <tbody>
    ${invalidEpstRows.join('')}

    </tbody>
  </Table>`;

  const validEpstRows = validEpstQuestions.map(row => {
    return `<tr className="table-success">
              <td>${row.numberOfQuestion}</td>
              <td>${row.section}</td>
              <td>${row.level}</td>
              <td>${row.message}</td>
          </tr>`;
  });

  const validEpstTable = `<Table responsive>
    <thead>
      <tr>
        <th scope="col">Número de pregunta</th>
        <th scope="col">Sección</th>
        <th scope="col">Nivel</th>
        <th scope="col">Mensaje</th>
      </tr>
    </thead>
    <tbody>
    ${validEpstRows.join('')}

    </tbody>
  </Table>`;

  return (
    <>
      <Card>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="align-items-center justify-content-between">
            <Col lg={3}>
              <img src={editing} className="img-fluid" alt="" />
            </Col>
            <Col lg={9} className="ps-lg-4 my-5 text-center text-lg-start">
              <h4 className="text-primary">Cargar Certificación EPSt</h4>
              <FileEpstUploader
                setValidEpstQuestions={setValidEpstQuestions}
                setInValidEpstQuestions={setInValidEpstQuestions}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {inValidEpstQuestions.length > 0 && (
        <Row>
          <Col lg={12} className="my-3 text-center text-lg-start">
            <FalconComponentCard noGuttersBottom className="h-100">
              <FalconComponentCard.Header
                title="Preguntas EPSt no creadas"
                noPreview
              >
                <p className="mt-2 mb-0">
                  Revise el motivo y valide el archivo XLSX para corregir los
                  errores.
                </p>
              </FalconComponentCard.Header>
              <FalconComponentCard.Body
                code={invalidEpstTable}
                language="jsx"
                // scope={{ ActionButton }}
                // noLight
                className="py-0"
              />
            </FalconComponentCard>
          </Col>
        </Row>
      )}

      {validEpstQuestions.length > 0 && (
        <Row>
          <Col lg={12} className="my-3 text-center text-lg-start">
            <FalconComponentCard noGuttersBottom className="h-100">
              <FalconComponentCard.Header
                title="Preguntas EPSt actualizadas o creadas"
                noPreview
              >
                <p className="mt-2 mb-0">
                  Estas preguntas se crearon satisfactoriamente.
                </p>
              </FalconComponentCard.Header>
              <FalconComponentCard.Body
                code={validEpstTable}
                language="jsx"
                // scope={{ ActionButton }}
                // noLight
                className="py-0"
              />
            </FalconComponentCard>
          </Col>
        </Row>
      )}
    </>
  );
};

export default UploadEpstCert;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getAdminAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};
