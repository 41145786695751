import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { epstCertActions } from 'store/epstCert';
import { useDispatch } from 'react-redux';
import { useFetcher } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../../assets/scss/wizard/epstSuccess.module.scss';

const EpstSuccess = ({ setIsLoading }) => {
  // eslint-disable-next-line no-unused-vars
  const [emptyScore, setEmptyScore] = useState(0);
  const dispatch = useDispatch();
  const fetcher = useFetcher();
  const { data, state } = fetcher;
  // const certResult = useSelector(state => state.epstCert.certResult);

  useEffect(() => {
    if (state === 'idle' && data && data.emetUserScore) {
      /* call dispatch function*/
      const emetUserScore = data.emetUserScore;
      dispatch(epstCertActions.setCertResult(emetUserScore));
      dispatch(epstCertActions.resetEpstCert());
      const totalUserScore = emetUserScore.reduce(
        (total, obj) => total + obj.userScore,
        0
      );
      setEmptyScore(totalUserScore);
      setIsLoading(false);
    }
  }, [state, data]);
  return (
    <>
      <Row className={styles.centerItems}>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">You have finished!</h4>
          <p className="fs-0">You have successfully completed your test</p>
          {/* <Button color="primary" className="px-5 my-3">
            Start Over
          </Button> */}
        </Col>
        <Col>
          <p className={`${styles.finalText}`}>
            Now that you have finished your test, wait for the reading and
            writing responses to be evaluated to obtain the final result. We
            recommend you to consult the{' '}
            <Link to="/dashboard/lms">dashboard</Link> section of our platform
            where the final results will be published.
          </p>
        </Col>
      </Row>
    </>
  );
};

EpstSuccess.propTypes = {
  setIsLoading: PropTypes.func.isRequired
};

export default EpstSuccess;
