import React from 'react';
import LogoutContent from 'components/authentication/LogoutContent';

const Logout = () => {
  return (
    <div className="text-center">
      <LogoutContent layout="card" titleTag="h3" />
    </div>
  );
};

export default Logout;
