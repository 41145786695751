import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import epstLogo from '../../../../assets/img/logos/epst.png';
import SimpleBarReact from 'simplebar-react';

const Epst = () => {
  const [epstData, setEpstData] = useState({});
  const location = useLocation();
  const stateCert = location.state;
  useEffect(() => {
    setEpstData(stateCert);
  }, []);
  return (
    <>
      <Card>
        <Card.Body>
          <Row className="align-items-center text-center mb-3">
            <Col sm={6} className="text-sm-start">
              <img src={epstLogo} alt="invoice" width={150} />
            </Col>
            <Col className="text-sm-end mt-3 mt-sm-0">
              <h2 className="mb-3">Certification</h2>
              <h5>Instituto CertifiED</h5>
              <p className="fs--1 mb-0">
                Hermosillo, Sonora 83140 <br /> México
              </p>
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-500">Certification to</h6>
              <h5>{epstData.name}</h5>
              <p className="fs--1">
                From: {epstData.college}
                <br />
                Birthday: {epstData.birthday}
                <br />
              </p>
              <p className="fs--1">
                <a>{epstData.email}</a>
              </p>
            </Col>
            <Col sm="auto" className="ms-auto">
              <div className="table-responsive">
                <Table borderless size="sm" className="fs--1">
                  <tbody>
                    <tr>
                      <th className="text-sm-end">Certification Id:</th>
                      <td>{epstData.certificationId}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Completion Date:</th>
                      <td>{epstData.finishedCertificationDate}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Evaluated Date:</th>
                      <td>{epstData.finishedEvaluatedDate}</td>
                    </tr>
                    <tr>
                      <th className="text-sm-end">Evaluated by:</th>
                      <td>{epstData.evaluatedBy}</td>
                    </tr>
                    <tr className="alert alert-success fw-bold">
                      <th className="text-sm-end">Level:</th>
                      <td>{epstData.level}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <div className="mt-4 fs--1">
            <SimpleBarReact>
              <Table className="border-bottom">
                <thead className="light">
                  <tr className="bg-primary text-white dark__bg-1000">
                    <th className="border-0 text-center">Grammar score</th>
                    <th className="border-0 text-center">Listening score</th>
                    <th className="border-0 text-center">Reading score</th>
                    <th className="border-0 text-center">Writing score</th>
                    <th className="border-0 text-center">Speaking score</th>
                    <th className="border-0 text-center">Total score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="align-middle text-center">
                      {epstData.grammarScore}
                    </td>
                    <td className="align-middle text-center">
                      {epstData.listeningScore}
                    </td>
                    <td className="align-middle text-center">
                      {epstData.readingScore}
                    </td>
                    <td className="align-middle text-center">
                      {epstData.writingScore}
                    </td>
                    <td className="align-middle text-center">
                      {epstData.speakingScore}
                    </td>
                    <td className="align-middle text-center">
                      {epstData.totalScore}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </SimpleBarReact>
          </div>
        </Card.Body>
        <Card.Footer className="bg-light">
          <p className="fs--1 mb-0">
            <strong>Instituto CerttifiED: </strong> {epstData.description}
          </p>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Epst;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getUserAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};
