import React from 'react';
import Search from '../../doc-components/users/Search';
const SearchUsers = () => {
  return (
    <>
      <Search />
    </>
  );
};

export default SearchUsers;

export const loader = async (/* { request, params } */) => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/admin/users/getUsersFilterData`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-USERS') {
        const coleges = data.get('coleges');
        const certifications = data.get('certifications');
        const userCreatedFrom = data.get('userCreatedFrom');
        const userCreatedTo = data.get('userCreatedTo');
        const status = data.get('status');

        const usersFilter = JSON.stringify({
          coleges: coleges,
          certifications: certifications,
          userCreatedFrom: userCreatedFrom,
          userCreatedTo: userCreatedTo,
          status: status
        });

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/admin/getUsers`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ usersFilters: usersFilter })
          }
        );
      } else if (trigger === 'CREATE') {
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/pending/pending`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};
