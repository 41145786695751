import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
// import styles from '../../../assets/scss/doc-components/searchUsers.module.scss';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import es from 'date-fns/locale/es';
import classNames from 'classnames';
import MultiSelect from 'components/common/MultiSelect';
import { useFetcher, useLoaderData } from 'react-router-dom';
import { format, parse, sub, add, addHours } from 'date-fns';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

const Search = () => {
  const [certificationCreatedFrom, setCertificationCreatedFrom] = useState();
  const [certificationCreatedTo, setCertificationCreatedTo] = useState();
  const [colegesOptions, setColegesOptions] = useState([]);
  const [kindCertOptions, setKindCertOptions] = useState([]);
  const [certStatusOptions, setCertStatusOptions] = useState([]);
  const [evaluatedByUsersOptions, setEvaluatedByUsersOptions] = useState([]);
  const [usersList, setUsersList] = useState([]);

  registerLocale('es', es);

  const fetcher = useFetcher();
  const { data, state } = fetcher;

  const certificationFilterData = useLoaderData();

  const validationFormSchema = yup
    .object({
      coleges: yup
        .array()
        .min(1, 'Seleccione al menos 1 colegio')
        .required('Campo requerido.'),
      kindCertification: yup
        .array()
        .min(1, 'Seleccione al menos 1 certificación')
        .required('Campo requerido.')
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationFormSchema)
  });

  const searchableUsersTableCode = `
      const columns = [
        {
          accessor: 'name',
          Header: 'Nombre'
        },{
          accessor: 'email',
          Header: 'Correo'
        },{
          accessor: 'college',
          Header: 'Colegio'
        },
        {
          accessor: 'evaluatedBy',
          Header: 'Evaluado Por'
        },
        {
          accessor: 'created',
          Header: 'Fecha'
        },
        {
          accessor: 'kindCertification',
          Header: 'Tipo Certificación'
        },
        {
          accessor: 'status',
          Header: 'Estatus'
        }
      ];
      
        const userData = [
          
          ${usersList.map(user => {
            return `{name: '${user.name}', email: '${user.email}' , college: '${user.college}', evaluatedBy: '${user.evaluatedBy}', created:'${user.created}', kindCertification:'${user.kindCertification}', status: '${user.status}' }`;
          })}
      
           ];
    
      function UsersSearchTable() {
      
        return (
          <AdvanceTableWrapper
            columns={columns}
            data={userData}
            sortable
            pagination
            perPage={5}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={userData.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        );
      }
      
      render(<UsersSearchTable />)`;

  async function onSubmit(formData) {
    let certificationCreateFromDateFormatted = '';
    let certificationCreateToDateFormatted = '';
    let certStatusFormatted = '';
    let evaluatedByUsersFormatted = '';
    if (certificationCreatedFrom) {
      const certificationCreatedDate = format(
        addHours(certificationCreatedFrom, -7),
        'dd-MM-yyyy HH:mm:ss'
      );
      certificationCreateFromDateFormatted = format(
        parse(certificationCreatedDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    } else {
      const pastDate = sub(new Date(), { years: 100 });
      const certificationCreatedDate = format(pastDate, 'dd-MM-yyyy HH:mm:ss');
      certificationCreateFromDateFormatted = format(
        parse(certificationCreatedDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    }

    if (certificationCreatedTo) {
      const certificationCreatedToDate = format(
        addHours(certificationCreatedTo, -7),
        'dd-MM-yyyy HH:mm:ss'
      );
      certificationCreateFromDateFormatted = format(
        parse(certificationCreatedToDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    } else {
      const pastDate = add(new Date(), { years: 100 });
      const certificationCreatedToDate = format(
        pastDate,
        'dd-MM-yyyy HH:mm:ss'
      );
      certificationCreateToDateFormatted = format(
        parse(certificationCreatedToDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    }

    if (formData.status) {
      certStatusFormatted = formData.status;
    } else {
      certStatusFormatted = [];
    }

    if (formData.evaluatedByUsers) {
      evaluatedByUsersFormatted = formData.evaluatedByUsers;
    } else {
      evaluatedByUsersFormatted = [];
    }

    fetcher.submit(
      {
        coleges: JSON.stringify(formData.coleges),
        kindCertification: JSON.stringify(formData.kindCertification),
        certificationCreatedFrom: certificationCreateFromDateFormatted,
        certificationCreatedTo: certificationCreateToDateFormatted,
        status: JSON.stringify(certStatusFormatted),
        evaluatedBy: JSON.stringify(evaluatedByUsersFormatted),
        trigger: 'GET-CERTIFICATIONS'
      },
      { method: 'POST' }
    );
  }

  useEffect(() => {
    if (certificationFilterData && certificationFilterData.filterData) {
      setColegesOptions(certificationFilterData.filterData.colegesOptions);
      setCertStatusOptions(
        certificationFilterData.filterData.certStatusOptions
      );
      setKindCertOptions(certificationFilterData.filterData.kindCertOptions);
      setEvaluatedByUsersOptions(
        certificationFilterData.filterData.evaluatedByUsersOptions
      );
    }
    if (state == 'idle' && data && data.certificationsData) {
      setUsersList(data.certificationsData.certifications);
    }
  }, [data, state]);

  const handleExport = () => {
    const userToExport = [];
    usersList.forEach(user => {
      const userFormatted = {
        nombre: user.name,
        correo: user.email,
        colegio: user.college,
        evaluadoPor: user.evaluatedBy,
        creado: user.created,
        tipoCertificaion: user.kindCertification,
        estatus: user.status
      };
      userToExport.push(userFormatted);
    });

    const worksheet = XLSX.utils.json_to_sheet(userToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'usuarios');
    XLSX.writeFile(workbook, 'certificacionesFiltradas.xlsx');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <h5>Filtrar certificationes EPSt por:</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Row>
                <Col md={8}>
                  <Form.Label>Colegio:</Form.Label>
                  <Controller
                    name="coleges"
                    render={({ field, ref }) => (
                      <MultiSelect
                        ref={ref}
                        placeholder="Colegios"
                        closeMenuOnSelect={false}
                        isMulti
                        options={colegesOptions}
                        className={classNames('mb-2', {
                          'is-invalid': errors.coleges
                        })}
                        {...field}
                      />
                    )}
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.coleges && errors.coleges.message}
                  </Form.Control.Feedback>
                </Col>
                <Col md={4}>
                  <Form.Label>Tipo Certificación:</Form.Label>
                  <Controller
                    name="kindCertification"
                    render={({ field, ref }) => (
                      <MultiSelect
                        ref={ref}
                        placeholder="Tipo Certificación"
                        closeMenuOnSelect={false}
                        isMulti
                        options={kindCertOptions}
                        className={classNames('mb-2', {
                          'is-invalid': errors.kindCertification
                        })}
                        {...field}
                      />
                    )}
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.kindCertification &&
                      errors.kindCertification.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  /*className={`${styles.dateDisplay}`} */ style={{
                    display: 'inline-grid'
                  }}
                >
                  <Form.Label>Desde:</Form.Label>
                  <DatePicker
                    name="certificacionCreatedFrom"
                    selected={certificationCreatedFrom}
                    /* onChange={date =>
                      setUserCreated(format(date, 'dd-MM-yyyy HH:mm:ss'))
                    }*/
                    onChange={date => setCertificationCreatedFrom(date)}
                    className={`form-control mb-2`}
                    placeholderText="Certificación creada"
                    timeIntervals={5}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    fixedHeight
                    locale="es"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.certificationCreatedFrom &&
                      errors.certificationCreatedFrom.message}
                  </Form.Control.Feedback>
                </Col>
                <Col
                  md={4}
                  /* className={`${styles.dateDisplay}`} */ style={{
                    display: 'inline-grid'
                  }}
                >
                  <Form.Label>Hasta:</Form.Label>

                  <DatePicker
                    name="certificacionCreatedTo"
                    selected={certificationCreatedTo}
                    /* onChange={date =>
                      setUserCreated(format(date, 'dd-MM-yyyy HH:mm:ss'))
                    }*/
                    onChange={date => setCertificationCreatedTo(date)}
                    className={`form-control mb-2`}
                    placeholderText="Certificatión creada"
                    timeIntervals={5}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    fixedHeight
                    locale="es"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.certificationCreatedTo &&
                      errors.certificationCreatedTo.message}
                  </Form.Control.Feedback>
                </Col>
                <Col md={4}>
                  <Form.Label>Estatus:</Form.Label>
                  <Controller
                    name="status"
                    render={({ field, ref }) => (
                      <MultiSelect
                        ref={ref}
                        placeholder="Estaus"
                        closeMenuOnSelect={false}
                        isMulti
                        options={certStatusOptions}
                        className={classNames('mb-2', {
                          'is-invalid': errors.status
                        })}
                        {...field}
                      />
                    )}
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.status && errors.status.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Label>Evaluado por:</Form.Label>
                  <Controller
                    name="evaluatedByUsers"
                    render={({ field, ref }) => (
                      <MultiSelect
                        ref={ref}
                        placeholder="Evaluado por"
                        closeMenuOnSelect={false}
                        isMulti
                        options={evaluatedByUsersOptions}
                        className={classNames('mb-2', {
                          'is-invalid': errors.status
                        })}
                        {...field}
                      />
                    )}
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.status && errors.status.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Row className="text-end">
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  className={`mt-3`}
                  size="sm"
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {usersList.length > 0 && (
        <FalconComponentCard>
          <FalconComponentCard.Header
            title="Resultado de búsqueda"
            light={false}
            className="border-bottom border-200"
            noPreview
          >
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <Button
                  onClick={handleExport}
                  size="sm"
                  className="rounded-pill me-1 mb-1"
                  variant="falcon-default"
                  transform="shrink-3"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: '5px' }}
                    icon={faCloudDownloadAlt}
                  />
                  Descargar
                </Button>
              </Col>
            </Row>
          </FalconComponentCard.Header>

          <FalconComponentCard.Body
            code={searchableUsersTableCode}
            scope={{
              AdvanceTableWrapper,
              AdvanceTable,
              AdvanceTableFooter,
              AdvanceTableSearchBox
            }}
            language="jsx"
            noInline
            noLight
          />
        </FalconComponentCard>
      )}
      {usersList.length <= 0 && (
        <Card>
          <Card.Body>Sin resultado</Card.Body>
        </Card>
      )}
    </>
  );
};

export default Search;
