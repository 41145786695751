import React from 'react';
import { Col, Row } from 'react-bootstrap';
import WizardEpst from 'components/wizard/WizardEpst';
import { useSelector } from 'react-redux';
import styles from '../../../../assets/scss/pages/certifications/epst/epstCertQuestions.module.scss';

const EpstCertQuestions = () => {
  const remainingTime = useSelector(state => state.epstCert.remainingTime);
  const currentSection = useSelector(state => state.epstCert.currentSection);

  // Handle the copy and paste events
  const handleCopy = e => {
    e.preventDefault(); // Prevent the copy action
  };

  // Handle the context menu event
  const handleContextMenu = e => {
    e.preventDefault(); // Prevent the context menu
  };

  return (
    <>
      {currentSection !== 'DONE' && currentSection !== 'BREAK' && (
        <Row className="align-items-center justify-content-between">
          <Col lg={12} className={`text-center ${styles.countDownTimer}`}>
            <p>{`Question time: ${remainingTime}`}</p>
          </Col>
        </Row>
      )}
      <Row
        onCopy={handleCopy}
        onContextMenu={handleContextMenu}
        className="align-items-center justify-content-between"
      >
        <Col lg={12} className="h-100">
          <WizardEpst variant="pills" validation={true} progressBar={true} />
        </Col>
      </Row>
    </>
  );
};

export default EpstCertQuestions;

export const action = async ({ request }) => {
  let resultAction = null;

  try {
    if (request.method === 'POST') {
      const data = await request.formData();
      const trigger = data.get('trigger');

      if (trigger === 'GET-NEXT-QUESTION') {
        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/epstCertification/getNextQuestion`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
      } else if (trigger === 'POST-ANSWER-QUESTION') {
        const selectedAnswer = data.get('selectedAnswer');
        const numberOfQuestion = data.get('numberOfQuestion');

        const sendAnswer = JSON.stringify({
          selectedAnswer: selectedAnswer,
          numberOfQuestion: numberOfQuestion
        });

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/epstCertification/answerQuestion`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ selectedAnswer: sendAnswer })
          }
        );
      } else if (trigger === 'POST-ANSWER-SPEAKING-QUESTION') {
        const numberOfQuestion = data.get('numberOfQuestion');
        const kindCertification = data.get('kindCertification');
        const email = data.get('email');

        resultAction = await fetch(
          `${process.env.REACT_APP_API_REMOTE_SERVER}api/epstCertification/answerSpeakingQuestion`,
          {
            method: 'POST',
            headers: {
              'content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
              numberOfQuestion: numberOfQuestion,
              kindCertification: kindCertification,
              email: email
            })
          }
        );
      }
    }
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }

  return resultAction;
};

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getUserAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};
