import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import { emetCertActions } from 'store/emetCert';
import { useDispatch, useSelector } from 'react-redux';
// import { useFetcher } from 'react-router-dom';
import PropTypes from 'prop-types';
import useBackendRequest from '../../hooks/useBackendRequest';
import styles from '../../assets/scss/wizard/emetSuccess.module.scss';

const EmetSuccess = ({ setIsLoading }) => {
  const [emptyScore, setEmptyScore] = useState(0);
  const dispatch = useDispatch();
  //const fetcher = useFetcher();
  // const { data, state } = fetcher;
  const certResult = useSelector(state => state.emetCert.certResult);
  const { sendRequest } = useBackendRequest();

  const applyReqResult = data => {
    const emetUserScore = data.emetUserScore;

    dispatch(emetCertActions.setStep(8));
    dispatch(emetCertActions.setCertResult(emetUserScore));
    dispatch(emetCertActions.resetEmetCert());

    const totalUserScore = emetUserScore.reduce(
      (total, obj) => total + obj.userScore,
      0
    );
    setEmptyScore(totalUserScore);
    setIsLoading(false);
    localStorage.removeItem('executedResult');
  };
  const resolveBackendError = () => {};

  useEffect(() => {
    const executedResult = localStorage.getItem('executedResult');
    if (certResult.length <= 0 && !executedResult) {
      localStorage.setItem('executedResult', 'true');
      sendRequest(
        {
          url: `${process.env.REACT_APP_API_REMOTE_SERVER}api/emetCertification/emetCertResult`,
          method: 'POST',
          headers: {
            'content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        },
        setIsLoading,
        applyReqResult,
        resolveBackendError
      );
    }
  }, []);

  return (
    <>
      <Row className={styles.centerItems}>
        {certResult && (
          <Col className="text-center">
            <div className="wizard-lottie-wrapper">
              <div className="wizard-lottie mx-auto">
                <Lottie animationData={celebration} loop={true} />
              </div>
            </div>
            <h4 className="mb-1">You have finished!</h4>
            <p className="fs-0">You have successfully completed your test</p>
            {/* <Button color="primary" className="px-5 my-3">
            Start Over
          </Button> */}
          </Col>
        )}

        <Col>
          <Table responsive className={styles.compactTable}>
            <thead>
              <tr>
                <th>Min</th>
                <th>Max</th>
                <th>Title</th>
                <th>Your score</th>
              </tr>
            </thead>
            <tbody>
              {certResult.map((score, index) => (
                <tr
                  className={
                    (emptyScore === 0 && index === 0) || score.userScore
                      ? 'table-success'
                      : 'table-secondary'
                  }
                  key={index}
                >
                  <td>{score.min}</td>
                  <td>{score.max}</td>
                  <td>{score.title}</td>
                  <td>
                    {score.userScore
                      ? score.userScore
                      : emptyScore === 0 && index === 0
                      ? 0
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

EmetSuccess.propTypes = {
  setIsLoading: PropTypes.func.isRequired
};

export default EmetSuccess;
