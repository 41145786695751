import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import QRCode from 'qrcode.react';

const QRCodeResults = () => {
  const qrcode = `${process.env.REACT_APP_QRC}?qrcode=true`;

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <h4>QR Code de resultados</h4>
              <QRCode value={qrcode} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default QRCodeResults;

export const loader = async () => {
  let resultAction = null;
  try {
    resultAction = await fetch(
      `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/getAdminAccess`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
  } catch (error) {
    throw new Response(null, { status: 500 });
  }

  if (!resultAction.ok && resultAction.status !== 422) {
    throw resultAction;
  }
  if (!resultAction.ok) {
    throw resultAction;
  }
  return resultAction;
};
