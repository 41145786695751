import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Alert, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from '../../assets/scss/wizard/epstReadingForm.module.scss';

const EpstReadingFrom = ({ register, errors }) => {
  const [flickering, setFlickering] = useState(false);
  // const [flickering, setFlickering] = useState('');

  const currentQuestion = useSelector(state => state.epstCert.currentQuestion);
  const answerOptions = useSelector(
    state => state.epstCert.currentQuestion.answer
  );

  useEffect(() => {
    setFlickering(true);
    setTimeout(() => {
      setFlickering(false);
    }, 1500); // Change the duration as needed
  }, [currentQuestion.numberOfQuestion]);

  return (
    <>
      <Row className={`${styles.instruction}`}>
        <Col>{currentQuestion.instruction}</Col>
      </Row>

      <Row className="mt-3">
        <Col className={`${styles.passageText}`}>
          {currentQuestion.passage.split(':').length > 1 && (
            <>
              <div className={`${styles.passageTitle} mb-2`}>
                {currentQuestion.passage.split(':')[0]}
              </div>
              <div>{currentQuestion.passage.split(':').slice(1).join(':')}</div>
            </>
          )}
          {currentQuestion.passage.split(':').length === 1 && (
            <div>{currentQuestion.passage.split(':').slice(0).join(':')}</div>
          )}
        </Col>

        <Col className={`${styles.colQuestionImage}`}>
          {currentQuestion.image && (
            <Image
              className={`${styles.questionImage}`}
              src={`${process.env.REACT_APP_API_REMOTE_SERVER}${currentQuestion.image}`}
              alt="question image"
            />
          )}
        </Col>
      </Row>
      <Row className={`${styles.question} mt-3`}>
        <Col className={`${flickering ? styles.flicker : ''}`}>
          {currentQuestion.question}
        </Col>
      </Row>

      <Row className={`${styles.answerOptions} mt-3`}>
        {answerOptions.map(answerOption => (
          <Col key={answerOption}>
            <Form.Check type="radio" id={answerOption}>
              <Form.Check.Input
                type="radio"
                {...register('userAnswer', {
                  required: 'Select any of the options before continue.'
                })}
                value={answerOption}
                isInvalid={errors['userAnswer']}
                isValid={
                  Object.keys(errors).length > 0 && !errors['userAnswer']
                }
              />
              <Form.Check.Label className="ms-2">
                {answerOption}
              </Form.Check.Label>
            </Form.Check>
          </Col>
        ))}
      </Row>
      <Row className={`${styles.errorMessage} mt-5`}>
        <Col>
          {errors['userAnswer'] && (
            <Alert variant="danger">{errors['userAnswer']?.message}</Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

EpstReadingFrom.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default EpstReadingFrom;
