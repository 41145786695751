import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Alert, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styles from '../../assets/scss/wizard/epstListeningForm.module.scss';
import EpstAudioPlayer from './EpstAudioPlayer';

const EpstListeningFrom = ({ register, errors }) => {
  const [flickering, setFlickering] = useState(false);
  const currentQuestion = useSelector(state => state.epstCert.currentQuestion);
  const answerOptions = useSelector(
    state => state.epstCert.currentQuestion.answer
  );

  useEffect(() => {
    setFlickering(true);
    setTimeout(() => {
      setFlickering(false);
    }, 1500); // Change the duration as needed
  }, [currentQuestion.numberOfQuestion]);

  return (
    <>
      <Row className={`${styles.instruction}`}>
        <Col>{currentQuestion.instruction}</Col>
      </Row>
      <Row className="mt-3">
        <Col className={`${styles.audio} ${styles.rowAudioQuestion}`}>
          <div className={`${styles.passageTitle} mb-4`}>
            {currentQuestion.passage}
          </div>

          <EpstAudioPlayer audioSrc={currentQuestion.audio} />
        </Col>
        <Col className={`${styles.colQuestionImage}`}>
          {currentQuestion.image && (
            <Image
              className={`${styles.questionImage}`}
              src={`${process.env.REACT_APP_API_REMOTE_SERVER}${currentQuestion.image}`}
              alt="question image"
            />
          )}
        </Col>
      </Row>
      <Row className={`${styles.question} mt-3`}>
        <Col className={`${flickering ? styles.flicker : ''}`}>
          {currentQuestion.question}
        </Col>
      </Row>

      <Row className={`${styles.answerOptions} mt-3`}>
        <Col>
          {answerOptions.map(answerOption => (
            <div key={answerOption} className="mb-2">
              <Form.Check type="radio" id={answerOption}>
                <Form.Check.Input
                  type="radio"
                  {...register('userAnswer', {
                    required: 'Select any of the options before continue.'
                  })}
                  value={answerOption}
                  isInvalid={errors['userAnswer']}
                  isValid={
                    Object.keys(errors).length > 0 && !errors['userAnswer']
                  }
                />
                <Form.Check.Label className="ms-2">
                  {answerOption}
                </Form.Check.Label>
              </Form.Check>
            </div>
          ))}
        </Col>
      </Row>
      <Row className={`${styles.errorMessage} mt-5`}>
        <Col>
          {errors['userAnswer'] && (
            <Alert variant="danger">{errors['userAnswer']?.message}</Alert>
          )}
        </Col>
      </Row>
    </>
  );
};

EpstListeningFrom.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default EpstListeningFrom;
