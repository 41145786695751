import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import logoutImg from 'assets/img/icons/spot-illustrations/45.png';

const LogoutContent = ({ /* layout, */ titleTag: TitleTag }) => {
  const handleLogout = async () => {
    try {
      const logout = await fetch(
        `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/logout`,
        {
          method: 'POST',
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      );
      if (logout.ok && logout.status === 200) {
        // delete localStorage values
        localStorage.removeItem('expiryDate');
        localStorage.removeItem('token');
        localStorage.removeItem('rdxState');
      } else if (!logout.ok && logout.status === 401) {
        console.log(logout.status);
      } else if (!logout.ok && logout.status === 500) {
        console.log(logout.error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleLogout();
  });

  return (
    <>
      {/* <img
        className="d-block mx-auto mb-4"
        src={logoutImg}
        alt="shield"
        width={100}
      /> */}
      <TitleTag>¡Nos vemos pronto!</TitleTag>
      <p>
        Gracias por utilizar nuestras herramientas. Saliste
        <br className="d-none d-sm-block" />
        correctamente del sistema.
      </p>
      <Button as={Link} color="primary" size="sm" className="mt-3" to={`/`}>
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Regresar a iniciar sesión
      </Button>
    </>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string
};

LogoutContent.defaultProps = {
  layout: 'card',
  titleTag: 'h4'
};

export default LogoutContent;
