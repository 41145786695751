import { createSlice } from '@reduxjs/toolkit';
const epstCertInitialState = {
  currentQuestion: {
    personType: '',
    section: '',
    level: '',
    numberOfQuestion: 0,
    kindCertification: '',
    instruction: '',
    question: '',
    image: '',
    passage: '',
    audio: '',
    time: 0,
    answer: []
  },
  remainingTime: 0,
  userAnswer: {},
  step: 1,
  speakingAnswered: false,
  currentSection: '',
  certResult: []
};

const epstCertSlice = createSlice({
  name: 'epstCertInfo',
  initialState: epstCertInitialState,
  reducers: {
    setCurrentQuestion(state, action) {
      if (
        action.payload.numberOfQuestion === 88888 ||
        (action.payload.numberOfQuestion === 99999 &&
          state.currentSection !== 'DONE' &&
          state.currentSection !== '')
      ) {
        state.step += 1;
      }
      state.currentQuestion = action.payload;
      state.remainingTime = action.payload.time;
      state.currentSection = action.payload.section;
    },
    setUserAnswer(state, action) {
      state.userAnswer = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    incrementStep(state) {
      state.step += 1;
    },
    setRemainingTime(state, action) {
      state.remainingTime = action.payload;
    },
    decrementRemainingTime(state) {
      state.remainingTime -= 1;
    },
    currentSection(state, action) {
      state.currentSection = action.payload;
    },
    setCertResult(state, action) {
      state.certResult = action.payload;
    },
    setSpeakingAnswered(state, action) {
      state.speakingAnswered = action.payload;
    },
    resetEpstCert(state) {
      state.currentQuestion = {
        personType: '',
        section: '',
        level: '',
        numberOfQuestion: 0,
        kindCertification: '',
        instruction: '',
        question: '',
        image: '',
        passage: '',
        audio: '',
        time: 0,
        answer: []
      };
      state.remainingTime = 0;
      state.speakingAnswered = false;
    }
  }
});

export const epstCertActions = epstCertSlice.actions;

export default epstCertSlice.reducer;
