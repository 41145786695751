import React, { useContext, useState } from 'react';
import NavbarDropdown from './NavbarDropdown';
import {
  /* dashboardRoutes, 
  appRoutes,
  pagesRoutes,
  modulesRoutes,  
  documentationRoutes, */
  certificationRoutes,
  administrationRoutes
} from 'routes/siteMaps';
import { Dropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { flatRoutes } from 'helpers/utils';
// import NavbarDropdownApp from './NavbarDropdownApp';
// import NavbarDropdownPages from './NavbarDropdownPages';
// import NavbarDropdownModules from './NavbarDropdownModules';
import AppContext from 'context/Context';
import { useSelector } from 'react-redux';

const NavbarTopDropDownMenus = () => {
  const userRole = useSelector(state => state.user.userInfo.userRole);

  // eslint-disable-next-line no-unused-vars
  const [dashboardLink, setDashboardRoute] = useState({
    page: {
      name: 'Dashboard',
      icon: 'rocket',
      to: '/dashboard/lms',
      active: true
    }
  });

  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };
  return (
    <>
      <Nav
        style={{
          justifyContent: 'center',
          /* paddingRight: '.5rem',
          paddingLeft: '.5rem '*/
          padding: '8px 0'
        }}
        className="flex-column"
        as="ul"
      >
        <Nav.Item>
          <Dropdown.Item
            className={dashboardLink.page.active ? 'link-600' : 'text-500'}
            to={dashboardLink.page.to}
            as={Link}
          >
            {dashboardLink.page.name}
          </Dropdown.Item>
        </Nav.Item>
      </Nav>

      {userRole === 'admin' && (
        <>
          <NavbarDropdown title="certificaciones">
            {flatRoutes(certificationRoutes.children).map(route => {
              if (route.permitions.includes(userRole)) {
                return (
                  <Dropdown.Item
                    key={route.name}
                    as={Link}
                    className={route.active ? 'link-600' : 'text-500'}
                    to={route.to}
                    onClick={handleDropdownItemClick}
                  >
                    {route.name}
                  </Dropdown.Item>
                );
              }
            })}
          </NavbarDropdown>
          <NavbarDropdown title="administración">
            {flatRoutes(administrationRoutes.children).map(route => {
              if (route.permitions.includes(userRole)) {
                return (
                  <Dropdown.Item
                    key={route.name}
                    as={Link}
                    className={route.active ? 'link-600' : 'text-500'}
                    to={route.to}
                    onClick={handleDropdownItemClick}
                  >
                    {route.name}
                  </Dropdown.Item>
                );
              }
            })}
          </NavbarDropdown>

          {/* {userRole === 'admin' && (
            
          )} */}
        </>
      )}
    </>
  );
};

export default NavbarTopDropDownMenus;
