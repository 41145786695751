import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import es from 'date-fns/locale/es';
import classNames from 'classnames';
import MultiSelect from 'components/common/MultiSelect';
import { useFetcher, useLoaderData } from 'react-router-dom';
import { format, parse, sub, add, addHours } from 'date-fns';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

const EmetEvaluatedCer = () => {
  const [certificationCreatedFrom, setCertificationCreatedFrom] = useState();
  const [certificationCreatedTo, setCertificationCreatedTo] = useState();
  const [colegesOptions, setColegesOptions] = useState([]);
  const [certificationList, setCertificationList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [reportTitle, setReportTitles] = useState([]);
  registerLocale('es', es);

  const fetcher = useFetcher();
  const { data, state } = fetcher;

  const certificationFilterData = useLoaderData();

  const validationFormSchema = yup
    .object({
      coleges: yup
        .array()
        .min(1, 'Seleccione al menos 1 colegio')
        .required('Campo requerido.'),
      email: yup.string().email('Escriba un correo válido')
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationFormSchema)
  });

  const searchableCertTableCode = `
      const columns = [
        {
          accessor: 'email',
          Header: 'Correo',
        },
        {
          accessor: 'name',
          Header: 'Nombre',
        },
        {
          accessor: 'college',
          Header: 'Colegio',
          sortable: true
        },
        {
          accessor: 'openCertificateDate',
          Header: 'Fecha apertura',
          sortable: true
        },
        {
          accessor: 'certification',
          Header: 'Tipo certificatión'
        },
        {
          accessor: 'test',
          Header: 'Test'
        },
        {
          accessor: 'grammarScore',
          Header: 'Grammar'
        },
        {
          accessor: 'listeningScore',
          Header: 'Listening'
        },
        {
          accessor: 'readingScore',
          Header: 'Reading'
        },
        {
          accessor: 'writingScore',
          Header: 'Writing'
          
        },
        {
          accessor: 'speakingScore',
          Header: 'Speaking'
        },
        {
          accessor: 'totalScore',
          Header: 'Total score'
        },
        {
          accessor: 'level',
          Header: 'Final level',
        },
        {
          accessor: 'created',
          Header: 'Fecha',
          
        }
      ];
      
        const certData = [
          
          ${certificationList.map(cert => {
            return `{
              email: '${cert.email}', 
              name: '${cert.name}', 
              college: '${cert.college}', 
              openCertificateDate: '${cert.openCertificateDate}', 
              certification: '${cert.certification}', 
              test:'${cert.test}', 
              grammarScore:'${cert.grammarScore}', 
              listeningScore:'${cert.listeningScore}', 
              readingScore:'${cert.readingScore}', 
              writingScore:'${cert.writingScore}',
              speakingScore:'${cert.speakingScore}',
              totalScore: '${cert.totalScore}',
              level: '${cert.level}',
              created: '${cert.created}'
            }`;
          })}
      
           ];
    
      function CertificationSearchTable() {
      
        return (
          <AdvanceTableWrapper
            columns={columns}
            data={certData}
            
            pagination
            perPage={5}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={certData.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        );
      }
      
      render(<CertificationSearchTable />)`;

  async function onSubmit(formData) {
    let certificationCreateFromDateFormatted = '';
    let certificationCreateToDateFormatted = '';
    if (certificationCreatedFrom) {
      const certificationCreatedDate = format(
        addHours(certificationCreatedFrom, -7),
        'dd-MM-yyyy HH:mm:ss'
      );
      certificationCreateFromDateFormatted = format(
        parse(certificationCreatedDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    } else {
      const pastDate = sub(new Date(), { years: 100 });
      const certificationCreatedDate = format(pastDate, 'dd-MM-yyyy HH:mm:ss');
      certificationCreateFromDateFormatted = format(
        parse(certificationCreatedDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    }

    if (certificationCreatedTo) {
      const certificationCreatedToDate = format(
        addHours(certificationCreatedTo, -7),
        'dd-MM-yyyy HH:mm:ss'
      );
      certificationCreateToDateFormatted = format(
        parse(certificationCreatedToDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    } else {
      const pastDate = add(new Date(), { years: 100 });
      const certificationCreatedToDate = format(
        pastDate,
        'dd-MM-yyyy HH:mm:ss'
      );
      certificationCreateToDateFormatted = format(
        parse(certificationCreatedToDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    }

    fetcher.submit(
      {
        coleges: JSON.stringify(formData.coleges),
        email: JSON.stringify(
          formData.email !== '' ? formData.email : undefined
        ),
        certificationCreatedFrom: certificationCreateFromDateFormatted,
        certificationCreatedTo: certificationCreateToDateFormatted,
        trigger: 'GET-EPST-EVALUATED'
      },
      { method: 'POST' }
    );
  }

  useEffect(() => {
    if (certificationFilterData && certificationFilterData.filterData) {
      setColegesOptions(certificationFilterData.filterData.colegesOptions);
    }
    if (state == 'idle' && data && data.certificationsData) {
      setCertificationList(data.certificationsData.certifications);
      setReportTitles(data.certificationsData.reportTitle);
    }
  }, [data, state]);

  const handleExport = () => {
    const certToExport = [];
    certificationList.forEach(cert => {
      const certFormatted = {
        Email: cert.email,
        Nombre: cert.name,
        Colegio: cert.college,
        Fecha_Apertura: cert.openCertificateDate,
        Tipo_Certificacion: cert.certification,
        TEST: cert.test,
        Grammar: cert.grammarScore,
        Listening: cert.listeningScore,
        Reading: cert.readingScore,
        Writing: cert.writingScore,
        Speaking: cert.speakingScore,
        Total_percentage: cert.totalScore,
        Final_level: cert.level,
        Fecha_Evaluado: cert.created
      };
      certToExport.push(certFormatted);
    });

    const worksheet = XLSX.utils.json_to_sheet(certToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'instituto');
    XLSX.writeFile(workbook, 'epstEvaluadas.xlsx');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <h5>Filtrar certificationes EPSt evaluadas:</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Row>
                <Col md={7}>
                  <Form.Label>Colegio:</Form.Label>
                  <Controller
                    name="coleges"
                    render={({ field, ref }) => (
                      <MultiSelect
                        ref={ref}
                        placeholder="Colegios"
                        closeMenuOnSelect={false}
                        isMulti
                        options={colegesOptions}
                        className={classNames('mb-2', {
                          'is-invalid': errors.coleges
                        })}
                        {...field}
                      />
                    )}
                    control={control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.coleges && errors.coleges.message}
                  </Form.Control.Feedback>
                </Col>
                <Col md={5}>
                  <Form.Label>Correo:</Form.Label>
                  <Controller
                    name="email"
                    render={({ field, ref }) => (
                      <Form.Control
                        name={field.name}
                        type="email"
                        className={classNames('mb-2', {
                          'is-invalid': errors.email
                        })}
                        ref={ref}
                        value={field.value || ''}
                        {...field}
                      />
                    )}
                    control={control}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  md={4}
                  /*className={`${styles.dateDisplay}`} */ style={{
                    display: 'inline-grid'
                  }}
                >
                  <Form.Label>Desde:</Form.Label>
                  <DatePicker
                    name="certificacionCreatedFrom"
                    selected={certificationCreatedFrom}
                    onChange={date => setCertificationCreatedFrom(date)}
                    className={`form-control mb-2`}
                    placeholderText="Certificación terminada de evaluar"
                    timeIntervals={5}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    fixedHeight
                    locale="es"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.certificationCreatedFrom &&
                      errors.certificationCreatedFrom.message}
                  </Form.Control.Feedback>
                </Col>
                <Col
                  md={4}
                  /* className={`${styles.dateDisplay}`} */ style={{
                    display: 'inline-grid'
                  }}
                >
                  <Form.Label>Hasta:</Form.Label>

                  <DatePicker
                    name="certificacionCreatedTo"
                    selected={certificationCreatedTo}
                    onChange={date => setCertificationCreatedTo(date)}
                    className={`form-control mb-2`}
                    placeholderText="Certificatión termianda de evaluar"
                    timeIntervals={5}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    fixedHeight
                    locale="es"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.certificationCreatedTo &&
                      errors.certificationCreatedTo.message}
                  </Form.Control.Feedback>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Row>
                <Col md={4}></Col>
              </Row>
            </Form.Group>
            <Row className="text-end">
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  className={`mt-3`}
                  size="sm"
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {certificationList.length > 0 && (
        <FalconComponentCard>
          <FalconComponentCard.Header
            title="Resultado de búsqueda"
            light={false}
            className="border-bottom border-200"
            noPreview
          >
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <Button
                  onClick={handleExport}
                  size="sm"
                  className="rounded-pill me-1 mb-1"
                  variant="falcon-default"
                  transform="shrink-3"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: '5px' }}
                    icon={faCloudDownloadAlt}
                  />
                  Descargar
                </Button>
              </Col>
            </Row>
          </FalconComponentCard.Header>

          <FalconComponentCard.Body
            code={searchableCertTableCode}
            scope={{
              AdvanceTableWrapper,
              AdvanceTable,
              AdvanceTableFooter,
              AdvanceTableSearchBox
            }}
            language="jsx"
            noInline
            noLight
          />
        </FalconComponentCard>
      )}
      {certificationList.length <= 0 && (
        <Card>
          <Card.Body>Sin resultado</Card.Body>
        </Card>
      )}
    </>
  );
};

export default EmetEvaluatedCer;
