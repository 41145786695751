import { createSlice } from '@reduxjs/toolkit';

const userInitialState = {
  userInfo: { userImg: '', userName: '', role: '', email: '' }
};

const userSlice = createSlice({
  name: 'userInfo',
  initialState: userInitialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    }
  }
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
