import React, { useState, useEffect } from 'react';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';

import { useForm } from 'react-hook-form';
import es from 'date-fns/locale/es';
import { useFetcher } from 'react-router-dom';
import { format, parse, sub, add, addHours } from 'date-fns';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

const CandidateResponses = () => {
  const [certificationCreatedFrom, setCertificationCreatedFrom] = useState();
  const [certificationCreatedTo, setCertificationCreatedTo] = useState();
  const [candidateStatics, setCandidateStatics] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [headerQuestions, setHeaderQuestions] = useState([]);
  registerLocale('es', es);

  const fetcher = useFetcher();
  const { data, state } = fetcher;

  const {
    handleSubmit,
    formState: { errors }
  } = useForm();

  async function onSubmit() {
    let finishedEvaluatedDateFrom = '';
    let finishedEvaluatedDateTo = '';
    if (certificationCreatedFrom) {
      const certificationCreatedDate = format(
        addHours(certificationCreatedFrom, 7),
        'dd-MM-yyyy HH:mm:ss'
      );
      finishedEvaluatedDateFrom = format(
        parse(certificationCreatedDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    } else {
      const pastDate = sub(new Date(), { years: 100 });
      const certificationCreatedDate = format(pastDate, 'dd-MM-yyyy HH:mm:ss');
      finishedEvaluatedDateFrom = format(
        parse(certificationCreatedDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    }

    if (certificationCreatedTo) {
      const certificationCreatedToDate = format(
        addHours(certificationCreatedTo, 7),
        'dd-MM-yyyy HH:mm:ss'
      );
      finishedEvaluatedDateTo = format(
        parse(certificationCreatedToDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    } else {
      const pastDate = add(new Date(), { years: 100 });
      const certificationCreatedToDate = format(
        pastDate,
        'dd-MM-yyyy HH:mm:ss'
      );
      finishedEvaluatedDateTo = format(
        parse(certificationCreatedToDate, 'dd-MM-yyyy HH:mm:ss', new Date()),
        'dd-MM-yyyy HH:mm:ss'
      );
    }

    fetcher.submit(
      {
        finishedEvaluatedFrom: finishedEvaluatedDateFrom,
        finishedEvaluatedTo: finishedEvaluatedDateTo,
        trigger: 'GET-STATICS-REPORT'
      },
      { method: 'POST' }
    );
  }

  useEffect(() => {
    if (state == 'idle' && data && data.staticsData) {
      setCandidateStatics(data.staticsData.candidateStatics);
      setHeaderQuestions(data.staticsData.questions);
    }
  }, [data, state]);

  const handleExport = () => {
    const headers = [
      'Email',
      'Name',
      ...headerQuestions.map(
        item =>
          `${item.numberOfQuestion} - ${item.personType} - ${item.question}`
      )
    ];

    const rows = candidateStatics.map(candidate => {
      const rowData = [
        candidate.email,
        candidate.name,
        ...candidate.statics.map(stat => stat.wasCorrect)
      ];

      return rowData;
    });

    const data = [headers, ...rows];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'instituto');

    // Exporting the file
    XLSX.writeFile(wb, 'candidateStatics.xlsx');
  };

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <h5>Filtrar reporte estadísticas EPSt:</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Row>
                <Col
                  md={4}
                  /*className={`${styles.dateDisplay}`} */ style={{
                    display: 'inline-grid'
                  }}
                >
                  <Form.Label>Desde:</Form.Label>
                  <DatePicker
                    name="certificacionCreatedFrom"
                    selected={certificationCreatedFrom}
                    onChange={date => setCertificationCreatedFrom(date)}
                    className={`form-control mb-2`}
                    placeholderText="Certificación terminada de evaluar"
                    timeIntervals={5}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    fixedHeight
                    locale="es"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.certificationCreatedFrom &&
                      errors.certificationCreatedFrom.message}
                  </Form.Control.Feedback>
                </Col>
                <Col
                  md={4}
                  /* className={`${styles.dateDisplay}`} */ style={{
                    display: 'inline-grid'
                  }}
                >
                  <Form.Label>Hasta:</Form.Label>

                  <DatePicker
                    name="certificacionCreatedTo"
                    selected={certificationCreatedTo}
                    onChange={date => setCertificationCreatedTo(date)}
                    className={`form-control mb-2`}
                    placeholderText="Certificatión termianda de evaluar"
                    timeIntervals={5}
                    showTimeSelect
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    fixedHeight
                    locale="es"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.certificationCreatedTo &&
                      errors.certificationCreatedTo.message}
                  </Form.Control.Feedback>
                </Col>
                <Col md={4}></Col>
              </Row>
              <Row>
                <Col md={4}></Col>
              </Row>
            </Form.Group>
            <Row className="text-end">
              <Col>
                <Button
                  variant="primary"
                  type="submit"
                  className={`mt-3`}
                  size="sm"
                >
                  Generar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {candidateStatics.length > 0 && (
        <FalconComponentCard>
          <FalconComponentCard.Header
            title="Reporte generado"
            light={false}
            className="border-bottom border-200"
            noPreview
          >
            <Row>
              <Col style={{ textAlign: 'center' }}>
                <Button
                  onClick={handleExport}
                  size="md"
                  className="rounded-pill me-1 mb-1"
                  variant="falcon-default"
                  transform="shrink-3"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: '5px' }}
                    icon={faCloudDownloadAlt}
                  />
                  Descargar
                </Button>
              </Col>
            </Row>
          </FalconComponentCard.Header>
        </FalconComponentCard>
      )}
      {candidateStatics.length <= 0 && (
        <Card>
          <Card.Body>Sin resultado</Card.Body>
        </Card>
      )}
    </>
  );
};

export default CandidateResponses;
