import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import emetCertReducer from './emetCert';
import epstCertReducer from './epstCert';
import { saveState, loadState } from './reduxLocalStorage';

const persistedState = loadState();

const store = configureStore({
  reducer: {
    user: userReducer,
    emetCert: emetCertReducer,
    epstCert: epstCertReducer
  },
  preloadedState: persistedState
});

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
