import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { addHours } from 'date-fns';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/user';

const LoginForm = ({ hasLabel }) => {
  const routeNavigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const validationFormSchema = yup.object({
    email: yup
      .string()
      .required('Campo requerido')
      .email('Escriba un correo válido'),
    password: yup.string().required('Campo requerido')
  });

  const { register, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(validationFormSchema)
  });

  const resolveBackendError = backErrors => {
    backErrors.forEach(bkError => {
      setError(bkError.param, { message: bkError.msg });
    });
  };

  // Handler
  async function onSubmit(formData) {
    try {
      const loginResult = await fetch(
        `${process.env.REACT_APP_API_REMOTE_SERVER}api/auth/login`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'content-type': 'application/json; charset=UTF-8' },
          body: JSON.stringify(formData)
        }
      );

      if (loginResult.ok && loginResult.status === 200) {
        const loginResponse = await loginResult.json();
        localStorage.setItem('token', loginResponse.token);

        /* We shuold set all the redux user global variables o data user infromation like (image, etc)  */
        const userInfo = {
          userName: `${loginResponse.userConfig.userName}`,
          userRole: `${loginResponse.userConfig.userRole}`,
          userImg: `${process.env.REACT_APP_API_REMOTE_SERVER}${loginResponse.userConfig.userRoleImg}`,
          email: `${loginResponse.userConfig.email}`
        };
        dispatch(userActions.setUserInfo(userInfo));

        localStorage.setItem('expiryDate', addHours(Date.now(), 6).toString());
        /* validate if come from QR code*/
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('qrcode') === 'true') {
          routeNavigate('/certifications/results/');
        } else {
          routeNavigate('/dashboard/lms');
        }
      } else if (!loginResult.ok && loginResult.status === 401) {
        const loginResponse = await loginResult.json();
        toast.error(`${loginResponse.message}`, {
          position: 'top-center',
          pauseOnHover: true
        });
      } else if (!loginResult.ok && loginResult.status === 422) {
        const loginResponse = await loginResult.json();
        resolveBackendError(loginResponse.validationErrors);
      } else if (!loginResult.ok && loginResult.status === 500) {
        routeNavigate('/errors/500', {
          state: {
            title: 'Whoops, algo salió mal!',
            errorCode: 500,
            message:
              'Intenta refrescar la pagina o regresa a intentarlo de nuevo. si el problema persiste,',
            footerMessage: `contáctanos`
          }
        });
      }
    } catch (error) {
      routeNavigate('/errors/500', {
        state: {
          title: 'Whoops, algo salió mal!',
          errorCode: 500,
          message:
            'Intenta refrescar la pagina o regresa a intentarlo de nuevo. si el problema persiste,',
          footerMessage: `contáctanos`
        }
      });
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h4>Iniciar sesión</h4>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Correo electrónico</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Correo electrónico' : ''}
          name="email"
          type="text"
          isInvalid={!!formState.errors.email}
          {...register('email')}
        />
        <Form.Control.Feedback type="invalid">
          {formState.errors.email && formState.errors.email.message}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Contraseña</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Contraseña' : ''}
          name="password"
          type="password"
          isInvalid={!!formState.errors.password}
          {...register('password')}
        />
        <Form.Control.Feedback type="invalid">
          {formState.errors.password && formState.errors.password.message}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Button type="submit" color="primary" className="mt-3 w-100">
          Acceder
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'card',
  hasLabel: false
};

export default LoginForm;
