import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import emet from 'assets/img/logos/emet.png';
import epst from 'assets/img/logos/epst.png';
import certified from 'assets/img/logos/certified.png';

// import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';

const Login = () => {
  return (
    <>
      <Row className="mb-5 text-center">
        <Col>
          <Image
            src={certified}
            alt="instituto certified"
            style={{ width: '100px', height: '100px' }}
          />
        </Col>
      </Row>
      <LoginForm layout="card" hasLabel />
      <Row className="mt-5 text-center">
        <Col>
          <Image
            src={emet}
            alt="emet"
            style={{ width: '80px', height: '80px' }}
          />
        </Col>
        <Col>
          <Image
            src={epst}
            alt="epst"
            style={{ width: '80px', height: '80px' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Login;
